import React from 'react'

import orderBy_ from 'lodash/orderBy'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'

import ShipmentDetails from '../subFeatures/ShipmentDetails'

import {
  createDisplayedShipmentType,
  createDisplayedDestinationName,
  createDisplayedDestinationState,
  createDisplayedShipmentDate,
  createDisplayedProNumber,
  createContainerTypesFieldsDefinitions, createDisplayedMovementType,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'

import {
  // Why are we using a bunch of functions from the
  // inboundEmptyKegShipmentHistory component? Because that component and this
  // component refer to the same shipments: inbound empty keg shipments for
  // Brewers are outbound empty keg shipments for Distributors. See
  // CODE_COMMENTS_205.
  getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
  getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable,
  getDisplayedHumanReadableStatusOfInboundEmptyKegShipment,
} from '../../../util/shipmentHistory/inboundEmptyKegShipmentHistory'

import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail,
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'

import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'
import { CUSTOMER_TYPES_WAREHOUSE } from '../../../../../../constants'


function createTableContentDefinitions({
  entireItemSkusSlice,
  customerId,
  configuredItemSkuIds,
  allOutboundEmptyKegShipmentObjects,
  entireCustomersSlice,
  dispatch,
}) {
  const useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged = true
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allOutboundEmptyKegShipmentObjects,
  })

  return {
    content: [
      {
        heading: 'Shipment #',
        cellContent: row => (
          <ShipmentDetails
            dispatch={dispatch}
            entireCustomersSlice={entireCustomersSlice}
            entireItemSkusSlice={entireItemSkusSlice}
            customerId={customerId}
            info={row}
            configuredItemSkuIds={configuredItemSkuIds}
            allOutboundEmptyKegShipmentObjects={allOutboundEmptyKegShipmentObjects}
            itemSkuIdsToIncludeAsFieldsInTable={determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail({
              entireItemSkusSlice,
              useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
              shipment: row,
            })}
          />
        ),
        customSortInfo: {
          sortFunction: (apiObjs, ascOrDesc) => (orderBy_(apiObjs, ['shipmentId'], [ascOrDesc])),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testShipmentNumber', // CODE_COMMENTS_57
      },
      (entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ? {
        heading: 'Shipment Type',
        cellContent: createDisplayedShipmentType,
        className: 'testShipmentType', // CODE_COMMENTS_57
      } : {
        heading: 'Movement Type',
        cellContent: createDisplayedMovementType,
        className: 'testMovementType', // CODE_COMMENTS_57
      }),
      {
        heading: 'Destination',
        cellContent: createDisplayedDestinationName,
        customSortInfo: {
          columnNotSortable: true, // CODE_COMMENTS_248
        },
        className: 'testDestination', // CODE_COMMENTS_57
      },
      {
        heading: 'Destination State',
        cellContent: createDisplayedDestinationState,
        className: 'testDestinationState', // CODE_COMMENTS_57
      },
      {
        heading: 'Ship Date',
        cellContent: createDisplayedShipmentDate,
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testShipmentDate', // CODE_COMMENTS_57
      },
      {
        heading: 'Status',
        cellContent: getDisplayedHumanReadableStatusOfInboundEmptyKegShipment,
        className: 'testStatus', // CODE_COMMENTS_57
      },
      {
        heading: 'Reference #',
        cellContent: createDisplayedProNumber(),
        className: 'testReferenceNumber', // CODE_COMMENTS_57
      },
      ...createContainerTypesFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
      }),
    ],
    defaultSortColumn: 'Ship Date',
    filterFunc: getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable,
    rowKey: row => row.id,
  }
}

export default ({
  entireItemSkusSlice,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  allOutboundEmptyKegShipmentObjects,
  configuredItemSkuIds,
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  areAnyItemsEditable,
  getIsindividualItemEditable,
  entireCustomersSlice,
  dispatch,
}) => {
  const allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable(
      allOutboundEmptyKegShipmentObjects,
    )
  const definitions = createTableContentDefinitions({
    entireItemSkusSlice,
    customerId,
    configuredItemSkuIds,
    allOutboundEmptyKegShipmentObjects,
    entireCustomersSlice,
    dispatch,
  })

  return (
    <HistoryTableCreator
      tableInfoObj={allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable}
      definitions={definitions}
      isFormSubmitting={isFormSubmitting}
      hasFormSubmitFailed={hasFormSubmitFailed}
      additionalPropsToPassToTableCells={{
        customerId,
        operatingContractBrewerCustomerId,
      }}
      noResultsMessage={noResultsMessage}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType="outbound empty keg shipment"
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      getIsindividualItemEditable={getIsindividualItemEditable}
    />
  )
}
