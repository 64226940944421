import React from 'react'


import moment from 'moment'
import HasPermissions from '../../../../../../common-components/HasPermissions'

import {
  getHasAnyInvoices,
} from '../../../../../../redux/selectors/customerBalances'
import customerBalancesFetchStatusSelectors from '../../../../../../redux/selectors/fetchStatuses/customers/customerBalances'

import {
  OrderKegsOption,
  OrderKegCollarsOption,
  ReportOutboundShipmentsOption,
  AcknowledgeInboundShipmentsOption,
  ReportBuybackShipmentsOption,
  ReportSelfDistAndPubShipmentsOption,
  ReportSelfCollectionShipmentsOption,
  RequestLocalReleaseAuthorizationOption,
  PayBalanceOption,
  ReportInventoryOption,
  ReportedVsCalculatedInventoryOption,
  ManageUsersOption,
  ManageContactsAndSubscriptionsOption,
  ManageAddressesOption,
  ReportKegFillsOption,
} from '../../shared/IndividualOptions'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
} from '../../../../../../constants/permissions'

import {
  createDisabledIfAnyOfTheseConditionsAreTrueFunc,
} from '../../util'

import {
  TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE,
  TEMPORARILY_DISABLE_CONTACTS_FEATURE,
} from '../../../../../../config'
import { getEntireSlice as getEntireCustomersSlice } from '../../../../../../redux/selectors/rewrite/customers'
import {
    getContracts,
    getEntireSlice as getEntireContractsSlice,
} from '../../../../../../redux/selectors/rewrite/contracts'
import {
    getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
    PPF_CONTRACT_TYPES_BRW,
    PPF_CONTRACT_TYPES_CONBRW,
} from '../../../../../../constants'


// CODE_COMMENTS_39
export default props => {
  const {
    customerId,
    currency,
    isRelatedToLocalDistributors,
    hasContractThatCanHaveKegsOrderedOnIt,
    shouldOrderKegCollarsFormBeRendered,
    shouldReportOutboundShipmentsFormBeRendered,
    shouldReportOutboundShipmentsFormOptionBeDisabled,
    whyReportOutboundShipmentsFormDisabledMessage,
    shouldReportBuybackShipmentsFormBeRendered,
    shouldReportSelfDistAndPubShipmentsFormBeRendered,
    shouldReportKegFillsFormBeRendered,
    shouldReportSelfCollectionShipmentsFormBeRendered,
    shouldReportInventoryFormBeRendered,
    shouldReportedInventoryVsCalculatedInventoryPageBeRendered,
  } = props
  return [
    ...(hasContractThatCanHaveKegsOrderedOnIt // CODE_COMMENTS_185
      ? [
        <HasPermissions
          key="OrderKegsOption"
          requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS}
          renderIfHasPermissions={
            () => (
              <OrderKegsOption
                disabledIf={
                  createDisabledIfAnyOfTheseConditionsAreTrueFunc({
                    customerId,
                    customerIsInactive: true,
                    customerIsOnCreditHold: true,
                    customerIsOnReportingHold: true,
                    customerIsOnSalesHold: true,
                    customerIsOnAcknowledgementHold: true,
                  })
                }
              />
            )
          }
        />,
      ]
      : []
    ),
    ...(
      shouldOrderKegCollarsFormBeRendered
        ? [
          <OrderKegCollarsOption
            key="OrderKegCollarsOption"
            disabledIf={
              createDisabledIfAnyOfTheseConditionsAreTrueFunc({
                customerId,
                customerIsInactive: true,
                customerIsOnCreditHold: true,
                customerIsOnReportingHold: true,
                customerIsOnAcknowledgementHold: true,
              })
            }
          />,
        ]
        : []
    ),
    ...(
      shouldReportKegFillsFormBeRendered
        ? [<ReportKegFillsOption key="ReportKegFillsOption" />]
        : []
    ),
    ...(
      shouldReportOutboundShipmentsFormBeRendered
        ? [
          <ReportOutboundShipmentsOption
            key="ReportOutboundShipmentsOption"
            // CODE_COMMENTS_231
            disabledIf={shouldReportOutboundShipmentsFormOptionBeDisabled}
            whyIsThisOptionDisabledMessage={whyReportOutboundShipmentsFormDisabledMessage}
          />,
        ]
        : []
    ),
    ...(hasContractThatCanHaveKegsOrderedOnIt // CODE_COMMENTS_185
      ? [
        <HasPermissions
          key="AcknowledgeInboundShipmentsOption"
          requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS}
          renderIfHasPermissions={
            () => (
              <AcknowledgeInboundShipmentsOption />
            )
          }
        />,
      ]
      : []
    ),
    ...(
      shouldReportBuybackShipmentsFormBeRendered
        ? [<ReportBuybackShipmentsOption key="ReportBuybackShipmentsOption" />]
        : []
    ),
    ...(
      shouldReportSelfDistAndPubShipmentsFormBeRendered
        ? [<ReportSelfDistAndPubShipmentsOption key="ReportSelfDistAndPubShipmentsOption" />]
        : []
    ),
    ...(
      shouldReportSelfCollectionShipmentsFormBeRendered
        ? [
          <ReportSelfCollectionShipmentsOption
              key="ReportSelfCollectionShipmentsOption"
              customerId={customerId}
              disabledIf={state => {
              const entireCustomersSlice = getEntireCustomersSlice(state)
              const entireContractsSlice = getEntireContractsSlice(state)
              const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
              const { contractEndDate } = getContracts({
                  entireContractsSlice,
                  entireCustomersSlice,
                  entireRelationshipsSlice,
                  customerId,
                  ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CONBRW],
                  notExpiredOnly: false,
                  activeStatusOnly: true,
                  noFutureContracts: true,
                  mostRecentOnly: true,
              })
              return (contractEndDate < moment())
              }}
          />,
          ]
        : []
    ),
    // don't show the "Request Local Release Authorization" card if the customer
    // isn't related to any local distributors
    ...(isRelatedToLocalDistributors
      ? [
        <HasPermissions
          key="RequestLocalReleaseAuthorizationOption"
          requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS}
          renderIfHasPermissions={
            () => (
              <RequestLocalReleaseAuthorizationOption customerId={customerId} />
            )
          }
        />,
      ]
      : []
    ),
    ...(!TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE && currency === 'USD'
      ? [
        <HasPermissions
          key="PayBalanceOption"
          requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES}
          renderIfHasPermissions={
            () => (
              <PayBalanceOption
                customerId={customerId}
                currency={currency}
                disabledIf={state => {
                  const { getFetchStatuses } = customerBalancesFetchStatusSelectors
                  const { didFetchSucceed: didFetchingBalancesSucceed } = getFetchStatuses(state, customerId)
                  const hasNoInvoices = getHasAnyInvoices(state, customerId) === false
                  // If fetching balances fails, we want the Pay Balances card to be
                  // enabled, even if the user ends up having no invoices, because the
                  // user will be able to re-try that fetch on the Pay Balances page
                  // (if the fetch then succeeds, they user will be presented with a
                  // message saying something like "You have no invoices on file").
                  return (didFetchingBalancesSucceed && hasNoInvoices)
                }}
              />
            )
          }
        />,
      ]
      : []
    ),
    ...(
      shouldReportInventoryFormBeRendered
        ? [<ReportInventoryOption key="ReportInventoryOption" />]
        : []
    ),
    ...(
      shouldReportedInventoryVsCalculatedInventoryPageBeRendered
        ? [<ReportedVsCalculatedInventoryOption key="ReportedVsCalculatedInventoryOption" {...props} />]
        : []
    ),
    <HasPermissions
      key="ManageUsersOption"
      requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
      renderIfHasPermissions={
        () => (
          <ManageUsersOption />
        )
      }
    />,
    ...(!TEMPORARILY_DISABLE_CONTACTS_FEATURE
      ? [
        <HasPermissions
          key="ManageContactsAndSubscriptionsOption"
          requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
          renderIfHasPermissions={
            () => (
              <ManageContactsAndSubscriptionsOption />
            )
          }
        />,
      ]
      : []
    ),
    <HasPermissions
      key="ManageAddressesOption"
      requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES}
      renderIfHasPermissions={
        () => (
          <ManageAddressesOption />
        )
      }
    />,
  ]
}

