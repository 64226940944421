import React, { Fragment } from 'react'


import KegOrderHistory from './individualTabs/KegOrderHistory'
import CollarOrderHistory from './individualTabs/CollarOrderHistory'
import InvoiceHistory from './individualTabs/InvoiceHistory'
import InventoryHistory from './individualTabs/InventoryHistory'
import InboundEmptyKegShipmentHistory from './individualTabs/InboundEmptyKegShipmentHistory'
import InboundFullKegShipmentHistory from './individualTabs/InboundFullKegShipmentHistory'
import OutboundEmptyKegShipmentHistory from './individualTabs/OutboundEmptyKegShipmentHistory'
import OutboundFullKegShipmentHistory from './individualTabs/OutboundFullKegShipmentHistory'
import KegFillHistory from './individualTabs/KegFillHistory'
import KegFillContracteeHistory from './individualTabs/KegFillContracteeHistory'
import ScheduledShipmentsHistory from './individualTabs/ScheduledShipmentsHistory'

import InformationalPopup from '../../common-components/InformationalPopup'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../redux/selectors/permissions'
import {
  getProp as getCustomerProp,
} from '../../redux/selectors/customers'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
  getContracts,
} from '../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getCustomerIdsOfAllRelatedTo,
} from '../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../redux/selectors/rewrite/users'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
  getSubsidiaryDisplayInvoiceHistory,
  getSubsidiaryKegCollarFeature,
} from '../../redux/selectors/rewrite/subsidiaries'
import {
  getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToViewInventoryReportHistory,
} from '../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'

import {
  isTruthyAndNonEmpty,
  sortByApiDate,
  sortByDateString,
} from '../../utils'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../constants/formAndApiUrlConfig/commonConfig'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_ORDER_HISTORY_TABLE,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
  FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_FILLS_HISTORY_TABLE,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_BREWER,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_CONTRACT_BREWER,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_DISTRIBUTOR,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS,
} from '../../constants/permissions'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,

  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CBMST,

  KEG_ORDER_HISTORY,
  COLLAR_ORDER_HISTORY,
  INVOICE_HISTORY,
  INVENTORY_HISTORY,
  INBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
  OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
  INBOUND_FULL_KEG_SHIPMENT_HISTORY,
  OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
  KEG_FILL_HISTORY,
  KEG_FILL_CONTRACTEE_HISTORY, // CODE_COMMENTS_232
  CUSTOMER_TYPES_WAREHOUSE,
  SCHEDULED_SHIPMENTS,
} from '../../constants'


export const createTabPanesByCustomerType = ({
  state,
  customerId,
  operatingContractBrewerCustomerId,
  isRentalCustomer,
  customerType,
  translate,
}) => {
  const panesDefinitions = []

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)

  const hasEverHadANonConbrwPpfContract = isTruthyAndNonEmpty(getContracts({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CBMST],
    notExpiredOnly: false,
    activeStatusOnly: false,
    noFutureContracts: true,
  }))

  let doesNonContracteeBrewerHaveAnyContractBrewers
  if (
    customerType !== CUSTOMER_TYPES_BREWER
    || operatingContractBrewerCustomerId
  ) {
    doesNonContracteeBrewerHaveAnyContractBrewers = false
  } else {
    const conbrwsRelatedTo = getCustomerIdsOfAllRelatedTo({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      onlyRelationshipsThatShipmentsCanBeReportedOn: false,
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
    })
    doesNonContracteeBrewerHaveAnyContractBrewers = isTruthyAndNonEmpty(conbrwsRelatedTo)
  }

  const operatingContractBrewerName = operatingContractBrewerCustomerId &&
    getCustomerProp(state, operatingContractBrewerCustomerId, 'name')

  mapCustomerTypeToHistoryTabs({
    customerType,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    isRentalCustomer,
    doesNonContracteeBrewerHaveAnyContractBrewers,
  }).forEach((tab, index) => {
    const TabComponent = mapTabToTabComponent(tab)
    const tabHtmlId = `${tab}-tab-button`
    const hasPermissionsToRenderThisTab = getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: mapTabToFunctionalityThatNeedsPermissions(
        tab,
        customerType,
        operatingContractBrewerCustomerId,
      ),
      // If this is a contractee history tab, pass in the contractee's
      // customerId in order to signal to this function that this is a
      // perCustomerPermissions check, not a userPermissions check
      customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId && customerId,
    })

    const passesAdditionalCustomQualificationsToRenderThisTab = getPassesAdditionalCustomQualificationsToRenderThisTab({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      customerId,
      operatingContractBrewerCustomerId,
      customerType,
      hasEverHadANonConbrwPpfContract,
      tab,
      entireSubsidiariesSlice,
    })

    if (
      hasPermissionsToRenderThisTab &&
      passesAdditionalCustomQualificationsToRenderThisTab
    ) {
      panesDefinitions.push({
        menuItem: {
          // adding a key here avoids a react console warning: 'Each child in an
          // array or iterator should have a unique "key" prop.'
          key: tabHtmlId,
          id: tabHtmlId,
          content: mapTabToHumanReadableTabTitle(
            tab,
            customerType,
            operatingContractBrewerName,
            translate,
          ),
        },
        // CODE_COMMENTS_42
        pane: {
          id: `${tab}-tab-content`,
          key: `Tab ${index}`,
          content: (
            <TabComponent
              customerId={customerId}
              operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
            />
          ),
        },
      })
    }
  })
  return panesDefinitions
}


export function createApiDateSortFunctionForHistoryTable({
  datePropName,
}) {
  return (rowsArray, ascOrDesc='desc') => (
    sortByApiDate({
      arr: rowsArray,
      nestedPath: [datePropName],
      descending: ascOrDesc === 'desc',
    })
  )
}


export function createDateStringSortFunctionForHistoryTable({
  datePropName,
  dateFormat=DEFAULT_DISPLAYED_DATE_FORMAT,
  sortNonDateStringsAToZInsteadOfThrowingError=true,
}) {
  return (rowsArray, ascOrDesc='desc') => (
    sortByDateString({
      arr: rowsArray,
      nestedPath: [datePropName],
      dateFormat,
      ascending: ascOrDesc === 'asc',
      sortNonDateStringsAToZInsteadOfThrowingError,
    })
  )
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/


function mapCustomerTypeToHistoryTabs({
  customerType,
  operatingContractBrewerCustomerId,
  isRentalCustomer,
  // doesNonContracteeBrewerHaveAnyContractBrewers,
}) {
  // mapping of tab types to customer types
  const masterHistoryTabs = [
    INVOICE_HISTORY,
  ]
  const brewerHistoryTabs = [
    KEG_ORDER_HISTORY,
    COLLAR_ORDER_HISTORY,
    INVOICE_HISTORY,
    INVENTORY_HISTORY,
    INBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
    OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
    // ...(doesNonContracteeBrewerHaveAnyContractBrewers ? [KEG_FILL_CONTRACTEE_HISTORY] : []),
  ]
  const contractBrewerHistoryTabs = [
    KEG_ORDER_HISTORY,
    INVOICE_HISTORY,
    INVENTORY_HISTORY,
    INBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
    KEG_FILL_HISTORY,
  ]
  const contracteeBrewerHistoryTabs = [
    COLLAR_ORDER_HISTORY,
    KEG_FILL_CONTRACTEE_HISTORY,
    // Note that there is no INBOUND_EMPTY_KEG_SHIPMENT_HISTORY tab for
    // contractee brewers. See CODE_COMMENTS_158 for why.
    OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
  ]

  const distributorHistoryTabs = [
    INVOICE_HISTORY,
    INVENTORY_HISTORY,
    INBOUND_FULL_KEG_SHIPMENT_HISTORY,
    OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
  ]

  const rentalCustomerHistoryTabs = [
    COLLAR_ORDER_HISTORY,
    INVOICE_HISTORY,
  ]

  const warehouseHistoryTabs = [
    INBOUND_FULL_KEG_SHIPMENT_HISTORY,
    OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
    INVENTORY_HISTORY,
    SCHEDULED_SHIPMENTS,
  ]

  const tabsByCustomerTypeMapping = {
    [CUSTOMER_TYPES_MASTER]: masterHistoryTabs,
    [CUSTOMER_TYPES_BREWER]: brewerHistoryTabs,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: contractBrewerHistoryTabs,
    [CUSTOMER_TYPES_DISTRIBUTOR]: distributorHistoryTabs,
    [CUSTOMER_TYPES_WAREHOUSE]: warehouseHistoryTabs,
  }

  if (operatingContractBrewerCustomerId) {
    return contracteeBrewerHistoryTabs
  }
  if (isRentalCustomer) {
    return rentalCustomerHistoryTabs
  }
  return tabsByCustomerTypeMapping[customerType]
}


function mapTabToHumanReadableTabTitle(
  tab,
  customerType,
  operatingContractBrewerName,
  translate,
) {
  const tabsToHumanReadableTabTitlesMapping = {
    [KEG_ORDER_HISTORY]: 'Keg Orders',
    [COLLAR_ORDER_HISTORY]: 'Collar Orders',
    [INVOICE_HISTORY]: 'Invoices',
    [INVENTORY_HISTORY]: (
      customerType === CUSTOMER_TYPES_DISTRIBUTOR
        ? 'Empty Keg Reports'
        : 'Reported Inventory'
    ),
    [INBOUND_EMPTY_KEG_SHIPMENT_HISTORY]: 'Inbound Empty Keg Shipments',
    [OUTBOUND_FULL_KEG_SHIPMENT_HISTORY]: 'Outbound Full Keg Shipments',
    [INBOUND_FULL_KEG_SHIPMENT_HISTORY]: 'Inbound Keg Shipments',
    [OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY]: 'Outbound Keg Shipments',
    [KEG_FILL_HISTORY]: 'Keg Fills',
    [KEG_FILL_CONTRACTEE_HISTORY]: (
      <Fragment>
        Keg Fills
        <InformationalPopup content={
          operatingContractBrewerName
            ? `Kegs ${operatingContractBrewerName} has filled for you`
            : 'Kegs your contract brewers have filled for you'
        }
        />
      </Fragment>
    ),
    [SCHEDULED_SHIPMENTS]: 'Scheduled Shipments',
  }
  return translate(tabsToHumanReadableTabTitlesMapping[tab])
}


function mapTabToTabComponent(tab) {
  const tabsToTabComponentsMapping = {
    [KEG_ORDER_HISTORY]: KegOrderHistory,
    [COLLAR_ORDER_HISTORY]: CollarOrderHistory,
    [INVOICE_HISTORY]: InvoiceHistory,
    [INVENTORY_HISTORY]: InventoryHistory,
    [INBOUND_EMPTY_KEG_SHIPMENT_HISTORY]: InboundEmptyKegShipmentHistory,
    [INBOUND_FULL_KEG_SHIPMENT_HISTORY]: InboundFullKegShipmentHistory,
    [OUTBOUND_FULL_KEG_SHIPMENT_HISTORY]: OutboundFullKegShipmentHistory,
    [OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY]: OutboundEmptyKegShipmentHistory,
    [KEG_FILL_HISTORY]: KegFillHistory,
    [KEG_FILL_CONTRACTEE_HISTORY]: KegFillContracteeHistory,
    [SCHEDULED_SHIPMENTS]: ScheduledShipmentsHistory,
  }
  return tabsToTabComponentsMapping[tab]
}


function mapTabToFunctionalityThatNeedsPermissions(tab, customerType, isThisAContracteeBrewerHistorySection) {
  let inventoryHistoryFunctionalityForThisCustomerType
  if (customerType === CUSTOMER_TYPES_BREWER) {
    inventoryHistoryFunctionalityForThisCustomerType =
      FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_BREWER
  } else if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    inventoryHistoryFunctionalityForThisCustomerType =
      FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_CONTRACT_BREWER
  } else { // must be a distributor
    inventoryHistoryFunctionalityForThisCustomerType =
      FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_DISTRIBUTOR
  }

  const tabsToTabComponentsMapping = {
    [KEG_ORDER_HISTORY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_ORDER_HISTORY_TABLE,
    [COLLAR_ORDER_HISTORY]:
      isThisAContracteeBrewerHistorySection
        ? FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE
        : FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
    [INVOICE_HISTORY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
    [INVENTORY_HISTORY]: inventoryHistoryFunctionalityForThisCustomerType,
    [INBOUND_EMPTY_KEG_SHIPMENT_HISTORY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE,
    [INBOUND_FULL_KEG_SHIPMENT_HISTORY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE,
    [OUTBOUND_FULL_KEG_SHIPMENT_HISTORY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE,
    [OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE,
    [KEG_FILL_HISTORY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_FILLS_HISTORY_TABLE,
    [KEG_FILL_CONTRACTEE_HISTORY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_FILLS_HISTORY_TABLE,
    [SCHEDULED_SHIPMENTS]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS,
  }
  return tabsToTabComponentsMapping[tab]
}


function getPassesAdditionalCustomQualificationsToRenderThisTab({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  customerId,
  // operatingContractBrewerCustomerId,
  customerType,
  hasEverHadANonConbrwPpfContract,
  tab,
  entireSubsidiariesSlice,
}) {
  if (tab === INVENTORY_HISTORY) {
    return getPassesAdditionalCustomQualificationsToRenderInventoryHistoryTab({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      customerId,
      customerType,
    })
  }
  if (tab === KEG_ORDER_HISTORY) {
    return getPassesAdditionalCustomQualificationsToRenderKegOrderHistoryTab({
      customerType,
      hasEverHadANonConbrwPpfContract,
    })
  }
  // eslint-disable-next-line no-empty
  if (tab === COLLAR_ORDER_HISTORY) {
    return getSubsidiaryKegCollarFeature({
      entireSubsidiariesSlice,
      subsidiaryId: entireCustomersSlice?.[customerId]?.subsidiaryId,
    })
  }

  if (tab === INVOICE_HISTORY) {
    return getSubsidiaryDisplayInvoiceHistory({
      entireSubsidiariesSlice,
      subsidiaryId: entireCustomersSlice?.[customerId]?.subsidiaryId,
    })
  }

  // all other tabs
  return true
}


function getPassesAdditionalCustomQualificationsToRenderInventoryHistoryTab({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  customerId,
  customerType,
}) {
  if (customerType !== CUSTOMER_TYPES_CONTRACT_BREWER) { return true }
  return getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToViewInventoryReportHistory({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    conbrwCustomerId: customerId,
  })
}


// CODE_COMMENTS_185: Don't render the Order Kegs history tab if a Brewer
// doesn't have a default PPF contract.
function getPassesAdditionalCustomQualificationsToRenderKegOrderHistoryTab({
  customerType,
  hasEverHadANonConbrwPpfContract,
}) {
  if (customerType !== CUSTOMER_TYPES_BREWER) { return true }
  return hasEverHadANonConbrwPpfContract
}


// e.g. NoMovements rows within the Outbound Full Keg Shipments history table
export const IS_SPECIAL_TABLE_ROW_HISTORY_OBJECT = 'IS_SPECIAL_TABLE_ROW_HISTORY_OBJECT'
