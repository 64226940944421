import { connect } from 'react-redux'


import acknowledgeInboundShipmentsForm from '../components/Form'

import {
  getEntireSlice as getEntireInboundUnacknowledgedShipmentsSlice,
} from '../../../redux/selectors/rewrite/inboundUnacknowledgedShipments'
import {
  getEntireSlice as getEntireCustomersSlice,
  getProp as getCustomerProp,
} from '../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../redux/selectors/rewrite/subsidiaries'
import { getUnacknowledgedShipmentSkus } from '../util'


/*
 * *****************************************************************************
 * mapStateToProps
 * *****************************************************************************
*/

const mapStateToProps = (state, { customerId }) => {
  const customers = getEntireCustomersSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const inboundUnacknowledgedShipmentsSliceForThisCustomer = getEntireInboundUnacknowledgedShipmentsSlice(
    state,
    customerId,
  )
  const businessUnitId = getCustomerProp(state, customerId, 'businessUnitId')
  const subsidiaryId = getCustomerProp(state, customerId, 'subsidiaryId')
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const itemSkuIdList = getCustomerProp(state, customerId, 'itemSkuIds')
  // eslint-disable-next-line max-len
  const itemSkuIds = getUnacknowledgedShipmentSkus(inboundUnacknowledgedShipmentsSliceForThisCustomer, entireItemSkusSlice, itemSkuIdList, subsidiaryId, customerType)

  return {
    customers,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
    businessUnitId,
    subsidiaryId,
    itemSkuIds,
  }
}


export default connect(
  mapStateToProps,
)(acknowledgeInboundShipmentsForm)
