import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'


import CustomerPortalOption from './CustomerPortalOption'
import RequestLocalAuthorizationPopup from './RequestLocalAuthorizationPopup'
import ReportedVsCalculatedInventoryCardCustomContent from './ReportedVsCalculatedInventoryCardCustomContent'

import { createRelativePath } from '../../../../../utils'

import {
  URL_PATHS,
} from '../../../../../constants'

import '../../../../../public/imagesAndIcons/iconsAndSvgsOfIcons/icons/styles.css'
import truckEnteringFactory from '../../../../../public/imagesAndIcons/iconsAndSvgsOfIcons/svg/truck-entering-factory.svg'
import truckEnteringFactoryGrey from '../../../../../public/imagesAndIcons/iconsAndSvgsOfIcons/svg/truck-entering-factory-grey.svg'
import truckLeavingFactory from '../../../../../public/imagesAndIcons/iconsAndSvgsOfIcons/svg/truck-leaving-factory.svg'
import truckLeavingFactoryGrey from '../../../../../public/imagesAndIcons/iconsAndSvgsOfIcons/svg/truck-leaving-factory-grey.svg'


export const OrderKegsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Order Kegs"
    className="icon-keg"
    linkTo={createRelativePath(match, URL_PATHS.orderKegs.path)}
    id="orderKegsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const OrderKegCollarsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Order Keg Collars"
    className="icon-keg-collar"
    linkTo={createRelativePath(match, URL_PATHS.orderKegCollars.path)}
    id="orderKegCollarsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ReportOutboundShipmentsOption = withRouter(({
  match,
  disabledIf,
  ...rest
}) => (
  <CustomerPortalOption
    text="Report Outbound Shipments"
    asImage
    imgSrc={truckLeavingFactory}
    alternateImgSrcIfDisabled={truckLeavingFactoryGrey}
    linkTo={createRelativePath(match, URL_PATHS.reportOutboundShipments.path)}
    id="reportOutboundShipmentsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
    {...rest}
  />
))


export const AcknowledgeInboundShipmentsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Acknowledge Inbound Shipments"
    asImage
    imgSrc={truckEnteringFactory}
    alternateImgSrcIfDisabled={truckEnteringFactoryGrey}
    linkTo={createRelativePath(match, URL_PATHS.acknowledgeInboundShipments.path)}
    id="acknowledgeInboundShipmentsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ReportBuybackShipmentsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Report Buyback Shipments"
    name="arrow left"
    linkTo={createRelativePath(match, URL_PATHS.reportBuybackShipments.path)}
    id="reportBuybackShipmentsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ReportSelfCollectionShipmentsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Report Self-Collection for Reuse"
    asImage
    imgSrc={truckEnteringFactory}
    linkTo={createRelativePath(match, URL_PATHS.reportSelfCollectionShipments.path)}
    id="reportSelfCollectionShipmentsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ReportSelfDistAndPubShipmentsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Report Self-Distribution and Own Pub Shipments"
    asImage
    imgSrc={truckEnteringFactory}
    alternateImgSrcIfDisabled={truckEnteringFactoryGrey}
    linkTo={createRelativePath(match, URL_PATHS.reportSelfDistAndPubShipments.path)}
    id="reportSelfDistAndPubShipmentsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const PayBalanceOption = withRouter(({ match, disabledIf, currency }) => (
  <CustomerPortalOption
    text="Pay Balance"
    name={currency === 'EUR' ? 'euro': 'dollar'}
    linkTo={createRelativePath(match, URL_PATHS.payBalance.path)}
    id="payBalanceOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ReportInventoryOption = withRouter(({ match, disabledIf, isDistributor, isWarehouseCustomer }) => (
  <CustomerPortalOption
    text={(isDistributor && !isWarehouseCustomer) ? 'Report Empty Kegs and Constellation Pallets' : 'Report Inventory'}
    name="cubes"
    linkTo={
      createRelativePath(
        match,
        isDistributor
          ? URL_PATHS.reportEmptyKegs.path
          : URL_PATHS.reportInventory.path,
      )
    }
    id="reportInventoryOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))

export const ReportedVsCalculatedInventoryOption = withRouter(props => {
  const {
    match,
    disabledIf,
  } = props
  return (
    <CustomerPortalOption
      {...props}
      text="Reported vs Calculated Inventory"
      customContentInPlaceOfIcon={ReportedVsCalculatedInventoryCardCustomContent}
      linkTo={createRelativePath(match, URL_PATHS.reportedInventoryVsCalculatedInventory.path)}
      id="reportedVsCalculatedInventoryOption" // CODE_COMMENTS_55
      disabledIf={disabledIf}
      // This feature came out in Nov '21
      labelAsNew={moment().isBefore(moment('2022-02-01'))}
    />
  )
})


export const ReportKegFillsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Report Keg Fills"
    className="icon-beertap"
    linkTo={createRelativePath(match, URL_PATHS.reportKegFills.path)}
    id="reportKegFillsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ManageUsersOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Manage Users"
    name="users"
    linkTo={createRelativePath(match, URL_PATHS.users.path)}
    id="manageUsersOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ManageContactsAndSubscriptionsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Manage Contacts and Email Subscriptions"
    name="mail"
    linkTo={createRelativePath(match, URL_PATHS.contacts.path)}
    id="manageSubscriptionsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ManageAddressesOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Addresses"
    name="address card"
    linkTo={createRelativePath(match, URL_PATHS.addresses.path)}
    id="manageAddressesOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const ManageProjectionsOption = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Manage Projections"
    name="line chart"
    linkTo={createRelativePath(match, URL_PATHS.projections.path)}
    id="manageProjectionsOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))


export const RequestLocalReleaseAuthorizationOption = withRouter(({ customerId, disabledIf }) => (
  <CustomerPortalOption
    text="Request Local Release Authorization"
    name="wpforms"
    popupInsteadOfLink
    popupComponent={RequestLocalAuthorizationPopup}
    propsForPopupComponent={{ customerId }}
    id="requestLocalReleaseAuthorizationOption" /* CODE_COMMENTS_55 */
    customerId={customerId}
    disabledIf={disabledIf}
  />
))


export const EmployeeOperateAsCustomerUserOption = withRouter(({
  disabledIf,
  ...rest
}) => (
  <CustomerPortalOption
    text="Operate as Customer User"
    name="user circle"
    linkTo={URL_PATHS.employeeOperateAsCustomerUser.path}
    id="employeeOperateAsCustomerUserOption" // CODE_COMMENTS_55
    disabledIf={disabledIf}
    {...rest}
  />
))

export const ViewAllInvoices = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="View All Invoices"
    name="file alternate"
    linkTo={createRelativePath(match, URL_PATHS.viewAllInvoices.path)}
    id="viewAllInvoices" // CODE_COMMENTS_55
    disabledIf={disabledIf}
  />
))

export const ManageAllContacts = withRouter(({ match, disabledIf }) => (
  <CustomerPortalOption
    text="Manage Contacts"
    name="mail"
    linkTo={createRelativePath(match, URL_PATHS.manageAllContacts.path)}
    id="manageAllContacts"
    disabledIf={disabledIf}
  />
))
