import React from 'react'


import withCustomerType from '../../../common-components/HOC/redux/withCustomerType'

import MasterPrelimInfoVerification from './MasterPrelimInfoVerification'
import BrewerPrelimInfoVerification from './BrewerPrelimInfoVerification'
import ContractBrewerPrelimInfoVerification from './ContractBrewerPrelimInfoVerification'
import DistributorPrelimInfoVerification from './DistributorPrelimInfoVerification'
import ContracteeBrewerPrelimInfoVerification from './ContracteeBrewerPrelimInfoVerification'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_WAREHOUSE,
} from '../../../constants'

export default withCustomerType(props => {
  const {
    customerType,
    componentToRenderIfVerified,
    operatingContractBrewerCustomerId: isContracteeBrewer, // CODE_COMMENTS_88
  } = props

  const VerificationComponentMap = {
    [CUSTOMER_TYPES_MASTER]: MasterPrelimInfoVerification,
    [CUSTOMER_TYPES_BREWER]: BrewerPrelimInfoVerification,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: ContractBrewerPrelimInfoVerification,
    [CUSTOMER_TYPES_DISTRIBUTOR]: DistributorPrelimInfoVerification,
    [CUSTOMER_TYPES_WAREHOUSE]: DistributorPrelimInfoVerification, // Use distributor validation itself
  }

  const VerificationComponentToRender = isContracteeBrewer
    ? ContracteeBrewerPrelimInfoVerification
    : VerificationComponentMap[customerType]

  return (
    <VerificationComponentToRender
      componentToRenderIfVerified={componentToRenderIfVerified}
      {...props}
    />
  )
})
