/* eslint-disable max-len */

import mergeWith_ from 'lodash/mergeWith'
import uniq_ from 'lodash/uniq'
import cloneDeep_ from 'lodash/cloneDeep'
import without_ from 'lodash/without'
import difference_ from 'lodash/difference'
import isString_ from 'lodash/isString'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'
import uniqFp_ from 'lodash/fp/uniq'


import {
  getHasPermissionsToPerformManageUsersPermissionsChoice,
} from '../redux/selectors/rewrite/permissions'
import {
  getIsRentalCustomer,
} from '../redux/selectors/rewrite/customers'
import {
  getAllChildCustomerObjectsOfCustomer,
} from '../redux/selectors/rewrite/children'

import {
  FUNCTIONALITY_TO_PERMISSIONS_MAP,
  MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP,
  CONTRACTEE_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES,

  RENTAL_CUSTOMER_MANAGE_USERS_PERMISSIONS_CHOICES,
  BREWER_MANAGE_USERS_PERMISSIONS_CHOICES,
  CONTRACT_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES,
  DISTRIBUTOR_MANAGE_USERS_PERMISSIONS_CHOICES,

  SPECIAL_CHILD_MANAGE_USERS_PERMISSIONS_CHOICES_MAPPED_TO_MASTER_CHOICES,
  UNIVERSAL_PERMISSIONS,
  WAREHOUSE_MANAGE_USERS_PERMISSIONS_CHOICES,
} from '../constants/permissions'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  CUSTOMER_TYPES_WAREHOUSE,
  CUSTOMER_TYPES_WEB_APP_ONLY_ALL,
} from '../constants'

import {
  isTruthyAndNonEmpty,
  reverseKeysAndValuesOfObjectOfFlatArrays,
} from './'


/*
 * *****************************************************************************
 * Converting to and from Permissions Maps
 * *****************************************************************************
*/

// CODE_COMMENTS_151: takes an array of permissions objects as received from the
// API:
//
// [
//   {permission: "ORDERS", access: "READ"},
//   {permission: "ORDERS", access: "CREATE"},
//   {permission: "SHIPMENTS", access: "READ"},
//   {permission: "SHIPMENTS", access: "CREATE"},
//   {permission: "SHIPMENTS", access: "DELETE"},
// ]
//
// and returns a map of permissions:
//
// {
// ORDERS: ['READ', 'CREATE'],
// SHIPMENTS: ['READ', 'CREATE', 'DELETE'],
// }
export function createPermissionsMap(permissions) {
  if (!isTruthyAndNonEmpty(permissions)) { return {} }
  return permissions.reduce(
    (acc, permissionObj) => ({
      ...acc,
      [permissionObj.permission]: (
        acc[permissionObj.permission]
          ? [
            ...acc[permissionObj.permission],
            permissionObj.access,
          ]
          : [permissionObj.access]
      ),
    }),
    {},
  )
}


// CODE_COMMENTS_151: takes a perCustomerPermissions object as received from the
// API:
//
// {
//   customerId1: [
//     {permission: "ORDERS", access: "READ"},
//     {permission: "ORDERS", access: "CREATE"},
//     {permission: "SHIPMENTS", access: "READ"},
//     {permission: "SHIPMENTS", access: "CREATE"},
//     {permission: "SHIPMENTS", access: "DELETE"},
//   ],
//   customerId2: [
//     {permission: "USER_PERMISSIONS", access: "READ"},
//     {permission: "USER_PERMISSIONS", access: "CREATE"},
//     {permission: "INVENTORY", access: "READ"},
//     {permission: "INVENTORY", access: "CREATE"},
//     {permission: "INVENTORY", access: "UPDATE"},
//   ],
// }
//
// and returns:
//
// {
//   customerId1: {
//     ORDERS: ['READ', 'CREATE'],
//     SHIPMENTS: ['READ', 'CREATE', 'DELETE'],
//   },
//   customerId2: {
//     USER_PERMISSIONS: ['READ', 'CREATE'],
//     INVENTORY: ['READ', 'CREATE', 'UPDATE'],
//   },
// }
export function createPerCustomerPermissionsMap(perCustomerPermissions) {
  if (!isTruthyAndNonEmpty(perCustomerPermissions)) { return {} }
  return Object.keys(perCustomerPermissions).reduce(
    (acc, customerId) => ({
      ...acc,
      [customerId]: createPermissionsMap(perCustomerPermissions[customerId]),
    }),
    {},
  )
}


// Does the opposite of createPermissionsMap()
export function convertPermissionsMapBackToPermissionsArray(permissionsMap) {
  return Object.keys(permissionsMap).reduce(
    (acc, permission) => {
      const accesses = permissionsMap[permission]
      const permissionObjectsToAddToMasterArray = accesses.map(access => ({ permission, access }))
      return [
        ...acc,
        ...permissionObjectsToAddToMasterArray,
      ]
    },
    [],
  )
}

// does the opposite of createPerCustomerPermissionsMap()
export function convertEntirePerCustomerPermissionsMapBackToPerCustomerPermissions(perCustomerPermissionsMap) {
  return Object.keys(perCustomerPermissionsMap).reduce(
    (acc, customerId) => {
      const permissionsArrayForThisCustomer = convertPermissionsMapBackToPermissionsArray(perCustomerPermissionsMap[customerId])
      return {
        ...acc,
        [customerId]: permissionsArrayForThisCustomer,
      }
    },
    {},
  )
}


/*
 * *****************************************************************************
 * Supersets & subsets of permissions
 * *****************************************************************************
*/

// We calculate this value when the app starts up for performance reasons,
// rather than dynamically when needed
export const manageUsersPermissionsChoicesThatAreSupersetsOfOthers =
  calculateWhichManageUsersPermissionsChoicesAreSupersetsOfOthers()
export const manageUsersPermissionsChoicesThatAreSubsetsOfOthers =
  reverseKeysAndValuesOfObjectOfFlatArrays(manageUsersPermissionsChoicesThatAreSupersetsOfOthers)


// returns an empty array if none
export function getWhichManageUserPermissionsChoicesAreASubsetOfThisOne(manageUsersPermissionsChoice) {
  return manageUsersPermissionsChoicesThatAreSupersetsOfOthers[manageUsersPermissionsChoice] || []
}


// returns an empty array if none
export function getWhichManageUserPermissionsChoicesAreASupersetOfThisOne(manageUsersPermissionsChoice) {
  return manageUsersPermissionsChoicesThatAreSubsetsOfOthers[manageUsersPermissionsChoice] || []
}


// Helper functions for this section


// Returns an object like this:
// {
//   MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS: [
//     'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS',
//   ]
// }
//
// where the keys are the supersets and the values are the subsets.
function calculateWhichManageUsersPermissionsChoicesAreSupersetsOfOthers() {
  const permissionsOfEachManageUsersPermissionsChoice = Object.keys(
    MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP,
  ).map(manageUsersPermissionsChoice => ({
    manageUsersPermissionsChoice,
    permissions: getAllPermissionsNeededForManageUsersPermissionsChoice(
      manageUsersPermissionsChoice,
    ),
  }))

  return permissionsOfEachManageUsersPermissionsChoice.reduce(
    (
      acc,
      {
        manageUsersPermissionsChoice,
        permissions,
      },
    ) => {
      const manageUsersPermissionsChoicesThatAreSubsetsOfThisOne =
        permissionsOfEachManageUsersPermissionsChoice.filter(potentialSubset => {
          if (potentialSubset.manageUsersPermissionsChoice === manageUsersPermissionsChoice) { return false }
          return getIsPermissionsMapSupersetOfOtherPermissionsMap(
            permissions,
            potentialSubset.permissions,
          )
        }).map(o => o.manageUsersPermissionsChoice)

      if (isTruthyAndNonEmpty(manageUsersPermissionsChoicesThatAreSubsetsOfThisOne)) {
        return {
          ...acc,
          [manageUsersPermissionsChoice]: manageUsersPermissionsChoicesThatAreSubsetsOfThisOne,
        }
      }
      return acc
    },
    {},
  )
}


/*
 * *****************************************************************************
 * Get All Manage Users permissions choices for a user.
 * *****************************************************************************
*/

export function getAllManageUsersPermissionsChoicesForAUserBasedOnRootCustomerType({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  userIdIfNotCurrentUser,
  getContracteePermissionsInsteadOfRootCustomerPermissions,
}) {
  if (getContracteePermissionsInsteadOfRootCustomerPermissions) {
    return CONTRACTEE_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES
  }
  const rootCustomerId = determineRootCustomerIdOfUser({
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
  })
  const isRentalCustomer = getIsRentalCustomer(entireCustomersSlice, rootCustomerId)
  if (isRentalCustomer) {
    return RENTAL_CUSTOMER_MANAGE_USERS_PERMISSIONS_CHOICES
  }
  const rootCustomerType = determineRootCustomerTypeOfUser({
    entireCustomersSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
  })
  if (rootCustomerType === CUSTOMER_TYPES_MASTER) {
    return getAllManageUsersPermissionsChoicesForAMasterUser({
      entireCustomersSlice,
      entireParentChildLinksSlice,
      customerId: rootCustomerId,
    })
  }
  return {
    [CUSTOMER_TYPES_BREWER]: BREWER_MANAGE_USERS_PERMISSIONS_CHOICES,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: CONTRACT_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES,
    [CUSTOMER_TYPES_DISTRIBUTOR]: DISTRIBUTOR_MANAGE_USERS_PERMISSIONS_CHOICES,
    [CUSTOMER_TYPES_WAREHOUSE]: WAREHOUSE_MANAGE_USERS_PERMISSIONS_CHOICES,
  }[rootCustomerType]
}

// CODE_COMMENTS_234
function getAllManageUsersPermissionsChoicesForAMasterUser({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
}) {
  const children = getAllChildCustomerObjectsOfCustomer({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
    onlyCustomersWhoAreNotCurrentlyInactive: true,
    // CODE_COMMENTS_49
    customerObjsCustomFilterFunc: o => o.customerType !== CUSTOMER_TYPES_SELF_DISTRIBUTION,
  })
  const childTypes = uniq_(children.map(o => o.customerType))
  return createAllManageUsersPermissionsChoicesForAMasterUserBasedOnChildTypes(childTypes)
}

// CODE_COMMENTS_234
function createAllManageUsersPermissionsChoicesForAMasterUserBasedOnChildTypes(childTypes) {
  const childTypesToChoicesMap = {
    [CUSTOMER_TYPES_BREWER]: BREWER_MANAGE_USERS_PERMISSIONS_CHOICES,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: CONTRACT_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES,
    [CUSTOMER_TYPES_DISTRIBUTOR]: DISTRIBUTOR_MANAGE_USERS_PERMISSIONS_CHOICES,
  }
  return flow_(
    mapFp_(childType => childTypesToChoicesMap[childType]),
    flattenFp_,
    mapFp_(childPermissionsChoice => (
      SPECIAL_CHILD_MANAGE_USERS_PERMISSIONS_CHOICES_MAPPED_TO_MASTER_CHOICES[childPermissionsChoice]
      || childPermissionsChoice
    )),
    flattenFp_,
    uniqFp_,
  )(childTypes)
}


/*
 * *****************************************************************************
 * Content displayed to the end user
 * *****************************************************************************
*/

// Sometimes you need to tell the user "You don't have these permissions: Do
// Something: READ & WRITE, Do Something Else: READ". This function creates such
// a message.
export function createYouDontHaveThesePermissionsMessage({
  // Either one of the keys in the FUNCTIONALITY_TO_PERMISSIONS_MAP object (e.g.
  // 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS') or an object with the
  // same shape as a FUNCTIONALITY_TO_PERMISSIONS_MAP value:
  //
  // {
  //   ORDERS: ['READ'],
  //   SHIPMENTS: ['READ', 'CREATE', 'UPDATE'],
  // }
  functionalityStringOrPermissionsMap,
  // 'You need these permissions' rather than 'You don't have these permissions'
  phraseAsNeedRatherThanDontHave,
  // returns a list of strings:
  // [
  //   'USERS: CREATE, UPDATE, DELETE',
  //   'USER_PERMISSIONS: READ, CREATE, UPDATE, DELETE',
  //   'CONTACTS: READ, CREATE, UPDATE, DELETE',
  // ]
  returnListInsteadOfString,
}) {
  const permissionsMap = isString_(functionalityStringOrPermissionsMap)
    ? FUNCTIONALITY_TO_PERMISSIONS_MAP[functionalityStringOrPermissionsMap]
    : functionalityStringOrPermissionsMap
  const premissionsStringsList = Object.entries(permissionsMap).map(([name, accessVals]) => (
    `${name}: ${accessVals.join(', ')}`
  ))
  if (returnListInsteadOfString) {
    return premissionsStringsList
  }
  const startOfString = `You ${phraseAsNeedRatherThanDontHave ? 'need' : "don't have"}`
  const thisOrThesePermissions = premissionsStringsList.length > 1 ? 'these permissions' : 'this permission'
  const premissionsString = premissionsStringsList.join('; ')
  return `${startOfString} ${thisOrThesePermissions}: ${premissionsString}`
}


/*
 * *****************************************************************************
 * Others
 * *****************************************************************************
*/

// Returns a single object of all needed permissions:
//
// {
//   SHIPMENTS: ['READ', 'CREATE', 'UPDATE', 'DELETE'],
//   RELATIONSHIPS: ['READ'],
// }
export function getAllPermissionsNeededForManageUsersPermissionsChoice(manageUsersPermissionsChoice) {
  const specificFunctionalitiesToGrant = MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP[manageUsersPermissionsChoice]
  return combinePermissionsOfMultipleFunctionalities(specificFunctionalitiesToGrant)
}


export function getAllManageUsersPermissionsChoicesUserOrCustomerHasPermissionsToDo({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  userIdIfNotCurrentUser,
  customerIdIfThisIsAPerCustomerPermissionsCheck,
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore, // CODE_COMMENTS_166
}) {
  const manageUsersPermissionsChoices = getAllManageUsersPermissionsChoicesForAUserBasedOnRootCustomerType({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
    customerIdIfThisIsAPerCustomerPermissionsCheck,
  })

  return manageUsersPermissionsChoices.filter(
    choice => getHasPermissionsToPerformManageUsersPermissionsChoice({
      entirePermissionsSlice,
      entireCurrentUserSlice,
      userIdIfNotCurrentUser,
      permissionsChoice: choice,
      customerIdIfThisIsAPerCustomerPermissionsCheck,
      customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore, // CODE_COMMENTS_166
    }),
  )
}


// Pass in an array of MANAGE_USERS_PERMISSIONS_CHOICE_... constants and
// this returns a single object of all needed permissions:
//
// { SHIPMENTS: ['READ', 'CREATE', 'UPDATE', 'DELETE'], RELATIONSHIPS: ['READ'] }
export function combinePermissionsOfMultipleManageUsersPermissionsChoices(
  choices,
) {
  const functionalitiesOfAllPermissionsChoices = flow_(
    // get an array of arrays of functionalities
    mapFp_(choice => MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP[choice]),
    // combine into one big array
    flattenFp_,
    // remove duplicates
    uniqFp_,
  )(choices)

  return combinePermissionsOfMultipleFunctionalities(functionalitiesOfAllPermissionsChoices)
}


// https://stackoverflow.com/a/40019375. Returns a new object--doesn't alter
// original objects passed in and doesn't include any duplicate access enums in
// the array values.
export function mergePermissionsObjects(permissionsObjects) {
  return mergeWith_(
    {},
    ...permissionsObjects,
    (objValue, srcValue) => uniq_((objValue || []).concat(srcValue)),
  )
}


// https://stackoverflow.com/a/40019375. Returns a new object--doesn't alter
// original objects passed in.
export function removeAllPermissionsOfOnePermissionsObjectFromAnotherPermissionsObject(
  objToRemoveFrom,
  objContainingPermissionsToRemove,
) {
  const toReturn = cloneDeep_(objToRemoveFrom) // don't mutate function arguments
  Object.keys(objContainingPermissionsToRemove).forEach(permission => {
    const originalAccessEnumsForThisPermission = toReturn[permission]
    if (isTruthyAndNonEmpty(originalAccessEnumsForThisPermission)) {
      toReturn[permission] = without_(
        originalAccessEnumsForThisPermission,
        ...objContainingPermissionsToRemove[permission],
      )
    }
  })
  return toReturn
}


// Pass in an array of FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_... constants and
// this returns a single object of all needed permissions:
//
// { SHIPMENTS: ['READ', 'CREATE', 'UPDATE', 'DELETE'], RELATIONSHIPS: ['READ'] }
export function combinePermissionsOfMultipleFunctionalities(functionalitiesThatNeedPermissions) {
  const permissionsObjects = functionalitiesThatNeedPermissions.map(functionalityThatNeedsPermissions => (
    FUNCTIONALITY_TO_PERMISSIONS_MAP[functionalityThatNeedsPermissions]),
  )
  return mergePermissionsObjects(permissionsObjects)
}


export function determineRootCustomerIdOfUser({
  entireCurrentUserSlice, // only required if userIdIfNotCurrentUser not passed in
  entireUsersSlice, // only required if userIdIfNotCurrentUser passed in
  userIdIfNotCurrentUser,
}) {
  return userIdIfNotCurrentUser
    ? entireUsersSlice[userIdIfNotCurrentUser].rootCustomerId
    : entireCurrentUserSlice.rootCustomerId
}


export function determineRootCustomerTypeOfUser({
  entireCustomersSlice,
  entireCurrentUserSlice, // only required if userIdIfNotCurrentUser not passed in
  entireUsersSlice, // only required if userIdIfNotCurrentUser passed in
  userIdIfNotCurrentUser,
}) {
  const rootCustomerId = determineRootCustomerIdOfUser({
    entireCurrentUserSlice, // only required if userIdIfNotCurrentUser not passed in
    entireUsersSlice, // only required if userIdIfNotCurrentUser passed in
    userIdIfNotCurrentUser,
  })
  return entireCustomersSlice[rootCustomerId].customerType
}


export function getIsPermissionsMapSupersetOfOtherPermissionsMap(possibleSuperset, other) {
  return Object.keys(other).every(permission => (
    // https://github.com/lodash/lodash/issues/1743#issuecomment-170598139
    difference_(other[permission], possibleSuperset[permission]).length === 0
  ))
}

// Transforms UNIVERSAL_PERMISSIONS into a single object with this structure:
// {
//   [CUSTOMERS]: READ,
//   [USERS]: READ,
//   [RELATIONSHIPS]: READ,
//   [CUSTOMER_SEARCH]: READ,
//   [PROPERTIES]: READ,
// }
export function getUniversalPermissionsObjectByRootCustomerType(rootCustomerType) {
  return UNIVERSAL_PERMISSIONS.reduce(
    (acc, o) => {
      if (
        o.customerTypes.includes(CUSTOMER_TYPES_WEB_APP_ONLY_ALL)
        || o.customerTypes.includes(rootCustomerType)
      ) {
        const existingEntry = acc[o.permission] || []
        existingEntry.push(o.access)
        return {
          ...acc,
          [o.permission]: existingEntry,
        }
      }
      return acc
    },
    {},
  )
}
