/* eslint-disable max-len */
import isFunction_ from 'lodash/isFunction'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'
import filterFp_ from 'lodash/fp/filter'


import { validateDate } from '../../../common-components/rewrite/ReactDatepicker'

import {
  getPropPassingInEntireCustomersSlice as getCustomerProp,
} from '../../../redux/selectors/rewrite/customers'
import {
  getItemSkusDefaultSortOrder,
  mapItemSkuIdsForQualityLevel,
  getNonCompositeSkuIdsOfCompositeSku, getItemSkuIdsOfAllQualityLevel,
} from '../../../redux/selectors/rewrite/itemSkus'

import {
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED,
  CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE,
  INVENTORY_REPORT_OBJS,
  REPORT_INVENTORY_CONFIG_OBJECT,
} from '../util/constants'

import { FIELD_NAME_DATE_COUNTED, FIELD_NAME_MONTH_REPORTING_FOR } from '../../../constants/formAndApiUrlConfig/reportInventory'
import {
  BUSINESS_UNIT_ID_TYPE_MICROSTAR,
  CUSTOMER_TYPES_DISTRIBUTOR, CUSTOMER_TYPES_WAREHOUSE,
  ITEM_SKU_IDS_MKM_HB_DEFAULT, ITEM_SKUS_SKU_DEFECTIVE_QUALITY_LEVEL, ITEM_SKUS_SKU_FULL_QUALITY_LEVEL,
  ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL,
} from '../../../constants'

import {
  SECTION_DEFINITIONS,
  SECTION_FIELDS,
  FIELD_NAME,
  FORM_FIELD_KIND,
  FORM_FIELD_KIND_INDIVIDUAL_FIELD,
  FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
  CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP,
  CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP,
  ONLY_RENDER_SECTION_IF,
  ONLY_RENDER_FIELD_IF,
  IS_FORM_FIELD_SUBMITTABLE,
  LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR,
  INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS,

  HEADINGS_AND_BRANDING_LANGUAGE,
  TITLE_OF_REPORT_FORM,
  TITLE_OF_EDIT_FORM,
  SECTION_KEY,
  ALL_TOP_SECTIONS,
} from '../util/configObjects'

import {
  determineItemSkuIdsToIncludeInInventoryReportFullDetailsOrEditForm,
  getIsKegQtyFormValueFilledOut,
  getReportingForMinAndMaxDates,
} from '../util'

import {
  sortArrayByTemplateArray,
  getAreFormValuesSameAsInitialValues,
} from '../../../utils'


export function getItemSkuIds({
  entireCustomersSlice,
  entireItemSkusSlice,
  customerId,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  isEditForm,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) {
  let itemSkuIds
  if (customerTypeForReportInventory === CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED || customerTypeForReportInventory === CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED) {
    // Assign anything here, because it will be overwritten by the function assigned to the
    // CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP prop in
    // src/features/ReportInventory/util/configObjects.js->reportInventoryConfigDistNoLoginRequired
    itemSkuIds = [ITEM_SKU_IDS_MKM_HB_DEFAULT]
  } else if (
    isEditForm
    || isThisForDisplayingAnAlreadyCreatedInventoryReport
  ) {
    // Remember that the item SKU Ids to include in this inventory report
    // might be different than the item SKU IDs the customer is currently
    // configured for--we're working with historical items here.
    itemSkuIds = determineItemSkuIdsToIncludeInInventoryReportFullDetailsOrEditForm({
      [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
      entireItemSkusSlice,
    })
  } else {
    itemSkuIds = getCustomerProp(entireCustomersSlice, customerId, 'itemSkuIds')
    const customerType = getCustomerProp(entireCustomersSlice, customerId, 'customerType')
    itemSkuIds = getItemSkuIdsForLoggedInReportInventoryForm({
      entireItemSkusSlice,
      itemSkuIds,
      customerType,
    })
  }
  itemSkuIds = sortArrayByTemplateArray(
    itemSkuIds,
    getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
  )
  return itemSkuIds
}


export function getInitialValues(props) {
  const {
    [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
    isEditForm,
    isThisForDisplayingAnAlreadyCreatedInventoryReport,
  } = props
  if (isThisForDisplayingAnAlreadyCreatedInventoryReport) { return null }
  if (!isEditForm) { return {} }

  const toReturn = {}
  const sectionDefinitions = isFunction_(configObj[SECTION_DEFINITIONS])
    ? configObj[SECTION_DEFINITIONS](props)
    : configObj[SECTION_DEFINITIONS]
  sectionDefinitions.forEach(def => {
    if (def[ONLY_RENDER_SECTION_IF] && !def[ONLY_RENDER_SECTION_IF](props)) { return }
    def[SECTION_FIELDS].forEach(field => {
      if (field[ONLY_RENDER_FIELD_IF] && !field[ONLY_RENDER_FIELD_IF](props)) { return }
      if (field[FORM_FIELD_KIND] === FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP) {
        const labelToApiInventoryMap = field[LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR](props)
        labelToApiInventoryMap.forEach((row, rowIndex) => {
          const itemSkuIdsForThisRow = getItemSkuIdsRenderedInThisRow({
            fieldDef: field,
            rowIndex,
            ...props,
          })
          itemSkuIdsForThisRow.forEach(itemSkuId => {
            toReturn[row.fieldNameFunc(itemSkuId)] = row.initialValueFunc(itemSkuId)
          })
        })
      }
      if (field[INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS]) {
        toReturn[field[FIELD_NAME]] = field[INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS](props)
      }
    })
  })
  return toReturn
}


export function getAreCurrentValuesSameAsInitialValues({
  formValues,
  initialValues,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) {
  if (isThisForDisplayingAnAlreadyCreatedInventoryReport) { return null }

  if (!formValues) { return false } // form has never been rendered

  return getAreFormValuesSameAsInitialValues(
    formValues,
    initialValues,
  )
}


export function getIsFormSubmittable(props) {
  const {
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
    customerType,
    formValues,
    // these fields are required only if this is an edit form
    initialValues,
    isEditForm,
    isThisForDisplayingAnAlreadyCreatedInventoryReport,
    configObjReturnedFromDistInvNoLoginConfigCall, // CODE_COMMENTS_267
  } = props
  if (!formValues) { return false } // form has never been rendered
  if (!inventoryReportObjs) { return false }

  // dist config call hasn't yet finished
  if (
    (customerTypeForReportInventory === CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED ||
      customerTypeForReportInventory === CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED
    )
    && (
      !configObjReturnedFromDistInvNoLoginConfigCall
      // There's one render tick after
      // configObjReturnedFromDistInvNoLoginConfigCall gets set but before
      // inventoryReportObjs gets set, and if we don't make sure that
      // inventoryReportObjs isn't null, an error will be thrown further down in
      // this function when we call the configObj[SECTION_DEFINITIONS]()
      // function.
      || !inventoryReportObjs
    )
  ) {
    return false
  }

  if (customerTypeForReportInventory === CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED) {
    const mkmCustomers = configObjReturnedFromDistInvNoLoginConfigCall?.customers?.[0]?.filter(o => o.businessUnitId === BUSINESS_UNIT_ID_TYPE_MICROSTAR)
    // eslint-disable-next-line no-unsafe-optional-chaining
    if (Object.values(formValues)?.length !== mkmCustomers?.length * 3 || Object.values(formValues).some(val => !val)) {
      // each customer will be havng 2 sku's. ie. loose paller & with product
      return false
    }
  }

  if (isThisForDisplayingAnAlreadyCreatedInventoryReport) { return null }

  if (
    !isEditForm
    && customerTypeForReportInventory !== CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE
    && customerTypeForReportInventory !== CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR
    && customerTypeForReportInventory !== CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED
    && customerTypeForReportInventory !== CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED
  ) {
    // If the Month Reporting For hasn't yet been selected, return false
    if (!formValues[FIELD_NAME_MONTH_REPORTING_FOR]) {
      return false
    }
  }

  if (
    !isEditForm
    && customerTypeForReportInventory !== CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE
    && customerTypeForReportInventory !== CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR // CODE_COMMENTS_270
    && customerTypeForReportInventory !== CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED
    && customerTypeForReportInventory !== CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED
  ) {
    const [
      minDate,
      maxDate,
    ] = getReportingForMinAndMaxDates({ formValues })
    const validationResult = validateDate({
      value: formValues[FIELD_NAME_DATE_COUNTED],
      minDate,
      maxDate,
    })
    if (!validationResult) {
      return false
    }
  }

  if (isEditForm) {
    const areCurrentValuesSameAsInitialValues = getAreCurrentValuesSameAsInitialValues({
      formValues,
      initialValues,
      ...props,
    })
    if (areCurrentValuesSameAsInitialValues) {
      return false
    }
  }


  const sectionDefinitions = isFunction_(configObj[SECTION_DEFINITIONS])
    ? configObj[SECTION_DEFINITIONS](props)
    : configObj[SECTION_DEFINITIONS]

  const [topSection, kegQtySections] = separateTopSectionFromKegQtySections(sectionDefinitions)
  // First, check whether the top section is filled out
  let areAllTopSectionFieldsSubmittable
  // the distributorNoLoginRequired form has no top section
  if (topSection) {
    [, areAllTopSectionFieldsSubmittable] = getAreAnyAndOrAllFormFieldsInASectionSubmittable(topSection, props)
  } else {
    areAllTopSectionFieldsSubmittable = true
  }
  if (!areAllTopSectionFieldsSubmittable) { return false }

  // Next, ensure the keg qty fields are filled out
  const results = kegQtySections.map(def => getAreAnyAndOrAllFormFieldsInASectionSubmittable(def, props))
  const areAnyKegQtyFieldsSubmittable = results.some(a => a[0])
  const areAllKegQtyFieldsSubmittable = results.every(a => a[1])

  return customerType === CUSTOMER_TYPES_DISTRIBUTOR
    ? areAnyKegQtyFieldsSubmittable // CODE_COMMENTS_271
    : areAllKegQtyFieldsSubmittable
}


export function getUiContainerProps({
  [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
  isEditForm,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) {
  if (
    isEditForm
    || isThisForDisplayingAnAlreadyCreatedInventoryReport
  ) {
    return {
      dontActuallyApplyTheUiContainerHoc: true,
    }
  }
  return {
    headerText: configObj[HEADINGS_AND_BRANDING_LANGUAGE][
      isEditForm ? TITLE_OF_EDIT_FORM : TITLE_OF_REPORT_FORM
    ],
  }
}


function separateTopSectionFromKegQtySections(sectionDefinitions) {
  let topSection = null
  const kegQtySections = []
  sectionDefinitions.forEach(section => {
    if (ALL_TOP_SECTIONS.includes(section[SECTION_KEY])) {
      topSection = section
    } else {
      kegQtySections.push(section)
    }
  })
  return [topSection, kegQtySections]
}


function getAreAnyAndOrAllFormFieldsInASectionSubmittable(
  def,
  props,
) {
  const {
    formValues,
  } = props
  let areAnyFieldsSubmittable = false
  let areAllFieldsSubmittable = true

  if (def[ONLY_RENDER_SECTION_IF] && !def[ONLY_RENDER_SECTION_IF](props)) { return [true, true] }
  def[SECTION_FIELDS].forEach(field => {
    if (field[ONLY_RENDER_FIELD_IF] && !field[ONLY_RENDER_FIELD_IF](props)) { return }
    if (field[FORM_FIELD_KIND] === FORM_FIELD_KIND_INDIVIDUAL_FIELD) {
      const func = field[IS_FORM_FIELD_SUBMITTABLE]
      if (func) {
        const result = func({ value: formValues[field[FIELD_NAME]], ...props })
        if (result) {
          areAnyFieldsSubmittable = true
        } else {
          areAllFieldsSubmittable = false
        }
      }
    }
    if (field[FORM_FIELD_KIND] === FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP) {
      const labelToApiInventoryMap = field[LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR](props)
      labelToApiInventoryMap.forEach((row, rowIndex) => {
        const itemSkuIdsForThisRow = getItemSkuIdsRenderedInThisRow({
          fieldDef: field,
          rowIndex,
          ...props,
        })
        itemSkuIdsForThisRow.forEach(itemSkuId => {
          const fieldName = row.fieldNameFunc(itemSkuId)
          const value = formValues[fieldName]
          if (getIsKegQtyFormValueFilledOut(value)) {
            areAnyFieldsSubmittable = true
          } else {
            areAllFieldsSubmittable = false
          }
        })
      })
    }
  })
  return [areAnyFieldsSubmittable, areAllFieldsSubmittable]
}

function getItemSkuIdsForLoggedInReportInventoryForm({
  entireItemSkusSlice,
  itemSkuIds,
  customerType,
}) {
  return flow_(
    mapFp_(itemSkuId => getNonCompositeSkuIdsOfCompositeSku({
      entireItemSkusSlice,
      itemSkuId,
    })),
    flattenFp_,
    // This will filter out pallets because all pallets have a null quality
    // level (as opposed to Unsorted)
    itemSkuIds_ => (customerType === CUSTOMER_TYPES_WAREHOUSE ? getItemSkuIdsOfAllQualityLevel({
      entireItemSkusSlice,
      itemSkuIds: itemSkuIds_,
      ignoreQualityLevel: [ITEM_SKUS_SKU_DEFECTIVE_QUALITY_LEVEL, ITEM_SKUS_SKU_FULL_QUALITY_LEVEL],
    }) : mapItemSkuIdsForQualityLevel({
      entireItemSkusSlice,
      itemSkuIds: itemSkuIds_,
      filterQualityLevel: ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL,
    })),
    filterFp_(itemSkuId => entireItemSkusSlice[itemSkuId].displayOnInventory),
    arr => sortArrayByTemplateArray(
      arr,
      getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
    ),
  )(itemSkuIds)
}


// The exact number of KeyQty form fields rendered for a specific row in the
// Report Inventory form are based on 3 things:
//
// 1. The customer's itemSkuIds prop
// 2. The CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP
//    value of the config object's field definition
// 3. The CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP
//    value of the config object's field definition
export function getItemSkuIdsRenderedInThisRow({
  fieldDef,
  rowIndex,
  ...props
}) {
  const {
    itemSkuIds,
  } = props
  let itemSkuIdsForThisRow = isFunction_(fieldDef[CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP])
    ? fieldDef[CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP](props)
    : fieldDef[CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]
  itemSkuIdsForThisRow = itemSkuIdsForThisRow || itemSkuIds

  const customRules = isFunction_(fieldDef[CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP])
    ? fieldDef[CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP](props)
    : fieldDef[CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]
  if (customRules) {
    itemSkuIdsForThisRow = itemSkuIdsForThisRow.filter((itemSkuIdForThisRowAndColumn, columnIndex) => (
      customRules?.[rowIndex]?.[columnIndex] || (props?.isDistReportConstellationNoLoginRequired && customRules?.[rowIndex]?.[rowIndex])
    ))
  }

  return itemSkuIdsForThisRow
}
