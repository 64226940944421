import {
  isStringValidator,
  isStringOrNumberValidator,
  isOneOfTheseStringsValidator,
  isFalsyValidator,
  isArrayOfStringsValidator,
} from './simpleValidators'

import {
  createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers,
  validatorCombinerWithOrLogic,
} from './util'

import {
  BUSINESS_UNIT_ID_TO_NAME_MAP,

  CUSTOMER_STATUS_ACTIVE,
  CUSTOMER_STATUS_INACTIVE,
  CUSTOMER_STATUS_HOLD,
  CUSTOMER_STATUS_PRE_PAY,

  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  CUSTOMER_TYPES_WAREHOUSE,
  CUSTOMER_TYPES_VENUE,
  CUSTOMER_TYPES_SELF_COLLECTION,
} from '../../../../constants'

import {
  logErrorMessage,
  logObjectHasProblemsErrorMessage,
  LOG_SEVERITY_ERROR,
  CAN_STILL_BE_DISPLAYED,
  PREVENTING_DASHBOARD_FROM_LOADING,
} from '../../../../utils/thirdPartyLogging'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'


// We need to export this because the 'children', 'relatedTo' and 'relatedFrom'
// api response data validations use it.
export const individualPropsValidatorsAndTransformersOfCustomerObjectReceivedFromCustomersCall = [
  // all the reps seem to be missing (ceRep, logisticsRep). These are handled
  // elsewhere--see CODE_COMMENTS_190.
  {
    propPath: 'businessUnitId',
    validatorFn: isOneOfTheseStringsValidator(Object.keys(BUSINESS_UNIT_ID_TO_NAME_MAP)),
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
  },
  {
    propPath: 'childrenIds',
    validatorFn: validatorCombinerWithOrLogic(isFalsyValidator, isArrayOfStringsValidator),
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
  },
  {
    propPath: 'customerStatus',
    validatorFn: isOneOfTheseStringsValidator([
      CUSTOMER_STATUS_ACTIVE,
      CUSTOMER_STATUS_INACTIVE,
      CUSTOMER_STATUS_HOLD,
      CUSTOMER_STATUS_PRE_PAY,
    ]),
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
  },
  {
    propPath: 'customerType',
    validatorFn: isOneOfTheseStringsValidator([
      CUSTOMER_TYPES_MASTER,
      CUSTOMER_TYPES_BREWER,
      CUSTOMER_TYPES_CONTRACT_BREWER,
      CUSTOMER_TYPES_DISTRIBUTOR,
      CUSTOMER_TYPES_PUB,
      CUSTOMER_TYPES_SELF_DISTRIBUTION,
      CUSTOMER_TYPES_WAREHOUSE,
      CUSTOMER_TYPES_VENUE,
      CUSTOMER_TYPES_SELF_COLLECTION,
    ]),
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
  },
  {
    propPath: 'id',
    validatorFn: isStringOrNumberValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
  },
  {
    propPath: 'name',
    validatorFn: isStringValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
  },
  {
    propPath: 'region',
    validatorFn: isStringValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
  },
  {
    propPath: 'tapcustomerId',
    // MASTER customers don't have tapCustomerIds
    validatorFn: props => {
      const { originalObj } = props
      if (originalObj.customerType === CUSTOMER_TYPES_MASTER) {
        return true
      }
      return isStringOrNumberValidator(props)
    },
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
  },
]

// `props` should be an object with at least one property: `data`
export function validateCustomerObjectReceivedFromCustomersCall(props) {
  const {
    httpResponse,
    data,
  } = props

  const results = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
    ...props,
    individualPropsValidatorsAndTransformers:
      individualPropsValidatorsAndTransformersOfCustomerObjectReceivedFromCustomersCall,
  })
  const {
    arrayOfProblemsWithData,
    canDataBeSavedToStore,
  } = results

  if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
    logObjectHasProblemsErrorMessage({
      objectType: 'customerObject',
      level: canDataBeSavedToStore ? CAN_STILL_BE_DISPLAYED : PREVENTING_DASHBOARD_FROM_LOADING,
      additionalInfo: {
        arrayOfProblemsWithData,
      },
      httpResponse,
    })
  }

  // CODE_COMMENTS_90
  if (
    // By checking to ensure that the customerType is one of these, we ensure
    // that the only customers who will receive this check are the
    // rootCustomerId of the logged-in user or child customers of a Master user
    // (excluding self-distribution customers, which can be children of a Master
    // but which don't need to be assigned any reps).
    [
      // Where's MASTER? See CODE_COMMENTS_191
      CUSTOMER_TYPES_BREWER,
      CUSTOMER_TYPES_CONTRACT_BREWER,
      CUSTOMER_TYPES_DISTRIBUTOR,
      CUSTOMER_TYPES_WAREHOUSE,
    ].includes(data.customerType)
  ) {
    const repTypesToCheck = ['ceRep', 'logisticsRep']
    if (
      repTypesToCheck.some(repType => (
        isStringOrNumberValidator({ data: data[repType] }) !== true
      ))
    ) {
      logErrorMessage({
        message: 'Customer Not Assigned Logistics Rep and/or CE Rep',
        severity: LOG_SEVERITY_ERROR,
        additionalInfo: {
          targetObject: data,
        },
        httpResponse,
      })
    }
  }

  return results
}
