import { connect } from 'react-redux'

import { getHumanReadableCustomerType } from '../../../utils'

import PopulatedChildCard from '../components/PopulatedChildCard'

import {
  getProp,
} from '../../../redux/selectors/customers'
import {
  getProp as getCurrentUserProp,
} from '../../../redux/selectors/currentUser'

import { getAddressOfCustomer } from '../../../redux/selectors/addresses'

import {
  ADDRESS_TYPES_BILLING,
} from '../../../constants'

const mapStateToProps = (state, { customerId }) => ({
  customerId,
  masterCustomerId: getCurrentUserProp(state, 'rootCustomerId'),
  customerStatus: getProp(state, customerId, 'customerStatus'),
  name: getProp(state, customerId, 'name'),
  customerType: getHumanReadableCustomerType(getProp(state, customerId, 'customerType')),
  region: getProp(state, customerId, 'region'),
  address: getAddressOfCustomer({
    state,
    customerId,
    currentlyActiveAddressesOnly: true, // CODE_COMMENTS_112 CODE_COMMENTS_116
    addressType: ADDRESS_TYPES_BILLING,
  }),
  tapcustomerId: getProp(state, customerId, 'tapcustomerId'),
})

export default connect(
  mapStateToProps,
)(PopulatedChildCard)
