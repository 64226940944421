import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

import RouteNotFound from '../../../../RouteNotFound'
import PrivateRoute from '../../../../../common-components/routes/PrivateRoute'

import PrelimInfoVerification from '../../../../PrelimInfoVerification'

import Dashboard from './BrewerDashboard'
import OrderKegs from '../../../../OrderKegs/OrderKegs'
import OrderKegCollars from '../../../../OrderKegCollars/OrderKegCollars'
import ReportOutboundShipments from '../../../../ReportShipments/ReportOutboundShipments'
import ReportKegFills from '../../../../ReportShipments/ReportKegFills'
import AcknowledgeInboundShipments from '../../../../AcknowledgeInboundShipments'
import ReportSelfDistAndPubShipments from '../../../../ReportShipments/ReportSelfDistAndPubShipments'
import ReportBuybackShipments from '../../../../ReportShipments/ReportBuybackShipments'
import ReportSelfCollectionShipments from '../../../../ReportShipments/ReportSelfCollectionShipments'
import PayBalance from '../../../../PayBalance'
import ReportInventory from '../../../../ReportInventory/ReportInventory'
import ReportedInventoryVsCalculatedInventory from '../../../../ReportedInventoryVsCalculatedInventory'
import ManageUsers from '../../../../ManageUsers'
import ManageContactsAndSubscriptions from '../../../../ManageContactsAndSubscriptions'
import ManageAddresses from '../../../../ManageAddresses'
// import ManageProjections from '../../../../ManageProjections'


import {
  URL_PATHS,
} from '../../../../../constants'

import { createRelativePath } from '../../../../../utils'
import RequestNewDistributor from '../../../../RequestNewDistributor/v2.0/'


// CODE_COMMENTS_26
const getRoutesList = props => {
  const {
    match,
    customerId,
    currency,
    hasOrderKegsPermissions,
    shouldOrderKegCollarsFormBeRendered,
    hasAcknowledgeShipmentsPermissions,
    hasPayBalancePermissions,
    shouldReportInventoryFormBeRendered,
    shouldReportedInventoryVsCalculatedInventoryPageBeRendered,
    hasManageUsersAndContactsPermissions,
    hasAddressesPermissions,

    hasNonExpiredNonConbrwPpfContract,

    shouldReportOutboundShipmentsFormBeRendered,
    shouldReportOutboundShipmentsFormOptionBeDisabled,
    shouldReportBuybackShipmentsFormBeRendered,
    shouldReportSelfDistAndPubShipmentsFormBeRendered,
    shouldReportKegFillsFormBeRendered,
    shouldReportSelfCollectionShipmentsFormBeRendered,
  } = props

  return (
    [
      <PrivateRoute
        exact
        key={URL_PATHS.root.path}
        path={createRelativePath(match, URL_PATHS.root.path)}
        render={
          routeProps => (
            <PrelimInfoVerification
              componentToRenderIfVerified={Dashboard}
              customerId={customerId}
              {...props}
              {...routeProps}
            />
          )
        }
      />,
      ...(
        hasNonExpiredNonConbrwPpfContract && // CODE_COMMENTS_185
        hasOrderKegsPermissions
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.orderKegs.path}
              path={createRelativePath(match, URL_PATHS.orderKegs.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={OrderKegs}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        shouldOrderKegCollarsFormBeRendered
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.orderKegCollars.path}
              path={createRelativePath(match, URL_PATHS.orderKegCollars.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={OrderKegCollars}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        shouldReportKegFillsFormBeRendered
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.reportKegFills.path}
              path={createRelativePath(match, URL_PATHS.reportKegFills.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ReportKegFills}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        shouldReportOutboundShipmentsFormBeRendered
        && !shouldReportOutboundShipmentsFormOptionBeDisabled
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.reportOutboundShipments.path}
              path={createRelativePath(match, URL_PATHS.reportOutboundShipments.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ReportOutboundShipments}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
            <PrivateRoute
              exact
              key={URL_PATHS.requestNewDistributor.path}
              path={createRelativePath(match, URL_PATHS.requestNewDistributor.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={RequestNewDistributor}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        shouldReportBuybackShipmentsFormBeRendered
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.reportBuybackShipments.path}
              path={createRelativePath(match, URL_PATHS.reportBuybackShipments.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ReportBuybackShipments}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        shouldReportSelfDistAndPubShipmentsFormBeRendered
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.reportSelfDistAndPubShipments.path}
              path={createRelativePath(match, URL_PATHS.reportSelfDistAndPubShipments.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ReportSelfDistAndPubShipments}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        shouldReportSelfCollectionShipmentsFormBeRendered
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.reportSelfCollectionShipments.path}
              path={createRelativePath(match, URL_PATHS.reportSelfCollectionShipments.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ReportSelfCollectionShipments}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        hasNonExpiredNonConbrwPpfContract && // CODE_COMMENTS_185
        hasAcknowledgeShipmentsPermissions
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.acknowledgeInboundShipments.path}
              path={createRelativePath(match, URL_PATHS.acknowledgeInboundShipments.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={AcknowledgeInboundShipments}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        hasPayBalancePermissions && currency === 'USD'
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.payBalance.path}
              path={createRelativePath(match, URL_PATHS.payBalance.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={PayBalance}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        shouldReportInventoryFormBeRendered
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.reportInventory.path}
              path={createRelativePath(match, URL_PATHS.reportInventory.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ReportInventory}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        shouldReportedInventoryVsCalculatedInventoryPageBeRendered
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.reportedInventoryVsCalculatedInventory.path}
              path={createRelativePath(match, URL_PATHS.reportedInventoryVsCalculatedInventory.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ReportedInventoryVsCalculatedInventory}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        hasManageUsersAndContactsPermissions
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.users.path}
              path={createRelativePath(match, URL_PATHS.users.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ManageUsers}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
            <PrivateRoute
              exact
              key={URL_PATHS.contacts.path}
              path={createRelativePath(match, URL_PATHS.contacts.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ManageContactsAndSubscriptions}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        hasAddressesPermissions
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.addresses.path}
              path={createRelativePath(match, URL_PATHS.addresses.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ManageAddresses}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
    ]
  )
}

export default withRouter(props => (
  <Switch>
    {getRoutesList(props)}
    <RouteNotFound />
  </Switch>
))
