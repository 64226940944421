// "for of" loops OK in this file, see CODE COMMENTS 50
/* eslint-disable no-restricted-syntax, require-yield, no-loop-func, max-len */

import { select, call, put } from 'redux-saga/effects'
import moment from 'moment'

import get_ from 'lodash/get'
import omit_ from 'lodash/omit'
import reduce_ from 'lodash/reduce'
import isArray_ from 'lodash/isArray'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'

import {
  SAVE_INVENTORY_HISTORY_ITEMS_OF_MULTIPLE_CUSTOMERS_WITHOUT_DELETING_ANY_EXISTING_HISTORY_ITEMS,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'

import {
  getProp as getCurrentUserProp,
  getCurrentUserFullName,
} from '../../../selectors/currentUser'

import {
  getProp as getCustomerProp,
} from '../../../selectors/customers'

import {
  getMostRecentlySubmittedFormValues,
} from '../../../selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'

import {
  mergeLineItemObjectsArrays,
} from '../../../selectors/rewrite/itemSkus'

import {
  INVENTORY_REPORT_OBJS,

  INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_PUBS,
  INVENTORY_REPORT_OBJS_CONTRACT_BREWERS,
  INVENTORY_REPORT_OBJS_CONTRACTEES,
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS,
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS,

  INFO_JUST_FOR_THE_WEB_APP,
  INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID,
  INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID,
  INFO_JUST_FOR_THE_WEB_APP_CUSTOMER_BUSINESS_UNIT_ID,
  CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS,
  CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS,
} from '../../../../features/ReportInventory/util/constants'

import {
  FIELD_NAME_COUNTED_BY,
  FIELD_NAME_DATE_COUNTED,
  FIELD_NAME_COMMENTS,

  FIELD_NAME_TOTAL_KEGS_FOREIGN,
  FIELD_NAME_EMPTY_CPP,
  API_INVENTORY_TYPE_EMPTY,
} from '../../../../constants/formAndApiUrlConfig/reportInventory'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  HISTORY_FORM_NAME_INVENTORY,
  ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,

  BUSINESS_UNIT_ID_TYPE_MICROSTAR,
  BUSINESS_UNIT_ID_TYPE_KEGCRAFT,
  BUSINESS_UNIT_ID_TYPE_KEGSTAR,

  ITEM_SKU_IDS_MKM_HB_DEFAULT,
  ITEM_SKU_IDS_MKM_SB_DEFAULT,
  ITEM_SKU_IDS_MKM_QB_DEFAULT,

  ITEM_SKU_IDS_KC_HB_DEFAULT,
  ITEM_SKU_IDS_KC_SB_DEFAULT,
  ITEM_SKU_IDS_KC_QB_DEFAULT,

  ITEM_SKU_IDS_KSK_HB_DEFAULT,
  ITEM_SKU_IDS_KSK_SB_DEFAULT,
  ITEM_SKU_IDS_KSK_QB_DEFAULT,
  CUSTOMER_TYPES_WAREHOUSE,
} from '../../../../constants'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  createContractMetadataObject,
} from '../../util/contractMetadataObject'

import {
  parseDynamicFormFieldName,
} from '../../../../features/ReportInventory/util'

import {
  formatArrayOfInventoryReportsToReduxStoreStructure,
} from '../../../../features/History/individualTabs/InventoryHistory/util/convertingToReduxStoreStructure'


import {
  formatDateForApiCall,
  createFormNameForRedux,
  doesHistoryItemDateFallWithinHistoryFormDateRange,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  createApiTimedTokenHttpHeader,
  isTruthyAndNonEmpty,
} from '../../../../utils'


/*
 * *****************************************************************************
 * API request bodies
 * *****************************************************************************
*/

// returns an array of inventory report objects
export function* createApiRequestBody(props) {
  let toReturn = yield call(addKegQuantitiesInfoToInventoryReportObjs, props)
  toReturn = yield call(addCommonPropsToInventoryReportObjs, {
    ...props,
    [INVENTORY_REPORT_OBJS]: toReturn,
  })
  toReturn = yield call(addContractMetadataObjectToInventoryReportObjs, { // CODE_COMMENTS_140
    ...props,
    [INVENTORY_REPORT_OBJS]: toReturn,
  })
  toReturn = yield call(removeInfoJustForWebAppPropFromInventoryReportObjs, {
    ...props,
    [INVENTORY_REPORT_OBJS]: toReturn,
  })
  // convert obj to simple array of individual inventoryReportObjs
  toReturn = reduce_(
    toReturn,
    (acc, invRepObjOrArrayOfInvRepObjs) => {
      if (isArray_(invRepObjOrArrayOfInvRepObjs)) {
        return [...acc, ...invRepObjOrArrayOfInvRepObjs]
      }
      return [...acc, invRepObjOrArrayOfInvRepObjs]
    },
    [],
  )
  // Distributors don't have to fill out every single keg qty field like other
  // customer types do (see CODE_COMMENTS_271). In fact, Distributors only have
  // to fill out _one_ keg qty field for the entire form. For no-login
  // Distributors (see CODE_COMMENTS_267), this means that entire customers can
  // have no keg qty fields at all filled out for them (each customer is
  // represented by a single row in a no-login Dist inventory form, and forms
  // can have an arbitrary number of rows). Consequently, some objects in
  // toReturn at this point could have an `inventoryLineItemObjects` prop which
  // is just an empty array. We want to filter out such customers, because
  // there's no point sending an inventoryReport object to the backend that
  // contains no inventory counts.
  toReturn = toReturn.filter(o => isTruthyAndNonEmpty(o.inventoryLineItemObjects))
  return toReturn
}


/*
 * *****************************************************************************
 * HTTP headers
 * *****************************************************************************
*/

// CODE_COMMENTS_140
export function* createHttpHeadersForReportInventoryCall({
  customerId,
  apiRequestBody,
  isDistReportInventoryNoLoginRequired,
  isDistReportConstellationNoLoginRequired,
  tokenForDistReportInventoryNoLoginRequired,
}) {
  if (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) {
    return createApiTimedTokenHttpHeader(tokenForDistReportInventoryNoLoginRequired)
  }
  const customerType = yield select(getCustomerProp, customerId, 'customerType')

  if (customerType !== CUSTOMER_TYPES_CONTRACT_BREWER) {
    return undefined
  }

  // This can happen in those unusual times when the Contract Brewer is not
  // currently brewing for any contractees (only happens if a CB is closing down
  // or something)
  if (apiRequestBody.length === 1) {
    return undefined
  }

  const randomContracteeBrewerCustomerId = apiRequestBody.map(o => (
    o.customerId
  )).find(customerIdIncludedInThisInventoryReport => (
    customerIdIncludedInThisInventoryReport !== customerId
  ))

  const headers = createHeadersForContracteeBrewerApiCall(randomContracteeBrewerCustomerId)

  return headers
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

function* addKegQuantitiesInfoToInventoryReportObjs(props) {
  const {
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    formValues,
  } = props
  const toReturn = {}
  for (const key of Object.keys(inventoryReportObjs)) {
    if ([
      INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ,
      INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ,
      INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ,
    ].includes(key)) {
      const o = inventoryReportObjs[key]
      const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId)
      let inventoryLineItemObjects = createInventoryLineItemObjectsArrayBasedOnReduxIdentifier(reduxIdentifier, formValues)
      if (o.inventoryLineItemObjects) {
        inventoryLineItemObjects = mergeLineItemObjectsArrays(o.inventoryLineItemObjects, inventoryLineItemObjects)
      }
      if (Object.keys(formValues).includes(FIELD_NAME_EMPTY_CPP)) {
        inventoryLineItemObjects = mergeLineItemObjectsArrays(
          inventoryLineItemObjects,
          {
            itemSkuId: ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
            inventoryType: API_INVENTORY_TYPE_EMPTY,
            quantity: Number(formValues[FIELD_NAME_EMPTY_CPP]),
          },
        )
      }
      toReturn[key] = { ...o, inventoryLineItemObjects }
    }
    if ([
      INVENTORY_REPORT_OBJS_PUBS,
    ].includes(key)) {
      toReturn[key] = inventoryReportObjs[key].map(o => {
        const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId)
        let inventoryLineItemObjects = createInventoryLineItemObjectsArrayBasedOnReduxIdentifier(reduxIdentifier, formValues)
        if (o.inventoryLineItemObjects) {
          inventoryLineItemObjects = mergeLineItemObjectsArrays(o.inventoryLineItemObjects, inventoryLineItemObjects)
        }
        return { ...o, inventoryLineItemObjects }
      })
    }
    if ([
      INVENTORY_REPORT_OBJS_CONTRACT_BREWERS,
      INVENTORY_REPORT_OBJS_CONTRACTEES,
    ].includes(key)) {
      toReturn[key] = inventoryReportObjs[key].map(o => {
        const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
          o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID],
          o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID],
        )
        let inventoryLineItemObjects = createInventoryLineItemObjectsArrayBasedOnReduxIdentifier(reduxIdentifier, formValues)
        if (o.inventoryLineItemObjects) {
          inventoryLineItemObjects = mergeLineItemObjectsArrays(o.inventoryLineItemObjects, inventoryLineItemObjects)
        }
        return { ...o, inventoryLineItemObjects }
      })
    }
    if ([
      INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS, // CODE_COMMENTS_267
    ].includes(key)) {
      const objs = flow_(
        mapFp_(i => i[INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]),
        flattenFp_,
      )(inventoryReportObjs[key])
      objs.forEach(o => {
        const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId)
        const businessUnitId = o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_CUSTOMER_BUSINESS_UNIT_ID]
        let inventoryLineItemObjects = createInventoryLineItemObjectsArrayBasedOnReduxIdentifier(reduxIdentifier, formValues)
        inventoryLineItemObjects = convertDistNoLoginInventoryLineItemObjectsArrayToCorrectItemSkusBasedOnBusinessUnitId({
          inventoryLineItemObjects,
          businessUnitId,
        })
        if (o.inventoryLineItemObjects) {
          inventoryLineItemObjects = mergeLineItemObjectsArrays(o.inventoryLineItemObjects, inventoryLineItemObjects)
        }
        // Here, we're using a combination of
        // INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS and the
        // customerId (AKA reduxIdentifier) as the key, because the keys need to
        // be distinct (they can't all be simply
        // INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS), but in other
        // functions within this file (specifically
        // addContractMetadataObjectToInventoryReportObjs), we need to be able
        // to distinguish the fact that it's a distNoLogin inventory object, so
        // we can't simply use the customerId.
        toReturn[`${INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS}${reduxIdentifier}`] = { ...o, inventoryLineItemObjects }
      })
    }
    if ([
      CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS, // CODE_COMMENTS_267
    ].includes(key)) {
      const objs = flow_(
        mapFp_(i => i[CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]),
        flattenFp_,
      )(inventoryReportObjs[key])
      objs.forEach(o => {
        const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId)
        const businessUnitId = o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_CUSTOMER_BUSINESS_UNIT_ID]
        let inventoryLineItemObjects = createInventoryLineItemObjectsArrayBasedOnReduxIdentifier(reduxIdentifier, formValues)
        inventoryLineItemObjects = convertDistNoLoginInventoryLineItemObjectsArrayToCorrectItemSkusBasedOnBusinessUnitId({
          inventoryLineItemObjects,
          businessUnitId,
        })
        if (o.inventoryLineItemObjects) {
          inventoryLineItemObjects = mergeLineItemObjectsArrays(o.inventoryLineItemObjects, inventoryLineItemObjects)
        }
        // Here, we're using a combination of
        // INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS and the
        // customerId (AKA reduxIdentifier) as the key, because the keys need to
        // be distinct (they can't all be simply
        // INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS), but in other
        // functions within this file (specifically
        // addContractMetadataObjectToInventoryReportObjs), we need to be able
        // to distinguish the fact that it's a distNoLogin inventory object, so
        // we can't simply use the customerId.
        toReturn[`${CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS}${reduxIdentifier}`] = { ...o, inventoryLineItemObjects }
      })
    }
  }
  return toReturn
}


function createInventoryLineItemObjectsArrayBasedOnReduxIdentifier(reduxIdentifier, formValues) {
  return reduce_(
    formValues,
    (acc, fieldValue, fieldName) => {
      // if the user left the value blank, don't include it in the result sent
      // to the backend. "Wait, I thought all keg qty fields must be filled in
      // by the user." Distributor customers can leave keg qty values blank
      // (although they must fill in _at least one_ keg qty value for the entire
      // form), see CODE_COMMENTS_271.
      if (!fieldValue) {
        return acc
      }
      if (fieldName === FIELD_NAME_TOTAL_KEGS_FOREIGN) {
        return mergeLineItemObjectsArrays(
          acc,
          {
            itemSkuId: ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,
            inventoryType: 'FGN',
            quantity: Number(formValues[FIELD_NAME_TOTAL_KEGS_FOREIGN]),
          },
        )
      }
      const dynamicFieldInfo = parseDynamicFormFieldName(fieldName)
      if (!dynamicFieldInfo) { // Not a kegQty field
        return acc
      }
      const {
        reduxIdentifier: reduxIdentifierOfField,
        apiInventoryType,
        itemSkuId,
      } = dynamicFieldInfo
      if (reduxIdentifierOfField === reduxIdentifier) {
        return mergeLineItemObjectsArrays(
          acc,
          {
            itemSkuId,
            inventoryType: apiInventoryType,
            quantity: Number(fieldValue),
          },
        )
      }
      return acc
    },
    [],
  )
}


// CODE_COMMENTS_280
function convertDistNoLoginInventoryLineItemObjectsArrayToCorrectItemSkusBasedOnBusinessUnitId({
  inventoryLineItemObjects,
  businessUnitId,
}) {
  const conversionMap = {
    [BUSINESS_UNIT_ID_TYPE_MICROSTAR]: {
      [ITEM_SKU_IDS_MKM_HB_DEFAULT]: ITEM_SKU_IDS_MKM_HB_DEFAULT,
      [ITEM_SKU_IDS_MKM_SB_DEFAULT]: ITEM_SKU_IDS_MKM_SB_DEFAULT,
      [ITEM_SKU_IDS_MKM_QB_DEFAULT]: ITEM_SKU_IDS_MKM_QB_DEFAULT,
    },
    [BUSINESS_UNIT_ID_TYPE_KEGCRAFT]: {
      [ITEM_SKU_IDS_MKM_HB_DEFAULT]: ITEM_SKU_IDS_KC_HB_DEFAULT,
      [ITEM_SKU_IDS_MKM_SB_DEFAULT]: ITEM_SKU_IDS_KC_SB_DEFAULT,
      [ITEM_SKU_IDS_MKM_QB_DEFAULT]: ITEM_SKU_IDS_KC_QB_DEFAULT,
    },
    [BUSINESS_UNIT_ID_TYPE_KEGSTAR]: {
      [ITEM_SKU_IDS_MKM_HB_DEFAULT]: ITEM_SKU_IDS_KSK_HB_DEFAULT,
      [ITEM_SKU_IDS_MKM_SB_DEFAULT]: ITEM_SKU_IDS_KSK_SB_DEFAULT,
      [ITEM_SKU_IDS_MKM_QB_DEFAULT]: ITEM_SKU_IDS_KSK_QB_DEFAULT,
    },
  }
  return inventoryLineItemObjects.map(lineItem => {
    const convertedItemSkuId = get_(conversionMap, [businessUnitId, lineItem.itemSkuId])
    if (convertedItemSkuId) {
      return {
        ...lineItem,
        itemSkuId: convertedItemSkuId,
      }
    }
    return lineItem
  })
}


// Keep in mind that no Distributor inventory reports (either logged in
// [CODE_COMMENTS_270] or not logged in [CODE_COMMENTS_267]) have a top section
// (the top section contains the dateCounted, countedBy and comments fields), so
// this function needs to retrieve information such as "countedBy" and "usereId"
// (which all inventoryReport objects sent to the backend must have) from
// elsewhere (specifically, either from the currentUser object [if the dist is
// logged in] or the configObjReturnedFromDistInvNoLoginConfigCall object [if
// not logged in]).
function* addCommonPropsToInventoryReportObjs(props) {
  const {
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    formValues,
    isEditForm,
    customerType,
    isDistReportInventoryNoLoginRequired,
    isDistReportConstellationNoLoginRequired,
    configObjReturnedFromDistInvNoLoginConfigCall,
  } = props
  const toReturn = {}
  for (const [key, invRepObjOrArrayOfInvRepObjs] of Object.entries(inventoryReportObjs)) {
    const arrayOfInvRepObjs = isArray_(invRepObjOrArrayOfInvRepObjs)
      ? invRepObjOrArrayOfInvRepObjs
      : [invRepObjOrArrayOfInvRepObjs]
    const newInvRepObjs = []
    for (const o of arrayOfInvRepObjs) {
      const countDate = (
        // CODE_COMMENTS_171: The user cannot change the  "Date Counted" field
        // when editing an existing report
        isEditForm
          ? o.countDate
          : formatDateForApiCall({
            date: (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired || customerType === CUSTOMER_TYPES_DISTRIBUTOR || customerType === CUSTOMER_TYPES_WAREHOUSE)
              ? moment()
              : formValues[FIELD_NAME_DATE_COUNTED],
          })
      )
      let countedBy
      if (isDistReportInventoryNoLoginRequired) {
        countedBy = `${configObjReturnedFromDistInvNoLoginConfigCall.firstName} ${configObjReturnedFromDistInvNoLoginConfigCall.lastName}`
      } else if (isDistReportConstellationNoLoginRequired) {
        countedBy = `recon ${new Date().getUTCFullYear()}`
      } else {
        // The "Counted By" field won't exist is this is a Distributor
        // Report/Edit Inventory form (see CODE_COMMENTS_207). Consequently, the
        // next lines will set countedBy, which is a required field, to the
        // current user
        const currentUserFullName = yield select(getCurrentUserFullName)
        countedBy = formValues[FIELD_NAME_COUNTED_BY] || currentUserFullName
      }

      const comments = formValues[FIELD_NAME_COMMENTS] || null

      const reportedByUser = (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
        ? null
        // If this is an edit form, userId and reportedByUser will already be
        // set on the object, so don't re-set it
        : o.reportedByUser || (yield select(getCurrentUserProp, 'userId'))

      newInvRepObjs.push({
        ...o,
        countDate,
        countedBy,
        comments,
        // Notice that reportPeriod (the "Month Reporting For" field) is missing:
        // see CODE_COMMENTS_171.

        reportedByUser,
        // do not pass in reportDate, the backend sets this automatically
      })
    }
    if (!isArray_(invRepObjOrArrayOfInvRepObjs)) {
      toReturn[key] = newInvRepObjs[0]
    } else {
      toReturn[key] = newInvRepObjs
    }
  }
  return toReturn
}


function* addContractMetadataObjectToInventoryReportObjs(props) {
  const {
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  } = props
  return reduce_(
    inventoryReportObjs,
    (acc, invRepObjOrArrayOfInvRepObjs, key) => {
      if ([
        INVENTORY_REPORT_OBJS_CONTRACT_BREWERS,
        INVENTORY_REPORT_OBJS_CONTRACTEES,
      ].includes(key)) {
        return {
          ...acc,
          [key]: invRepObjOrArrayOfInvRepObjs.map(o => ({
            ...o,
            _contractMetadataObject: createContractMetadataObject(
              o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID],
              null,
            ),
          })),
        }
      }
      if (
        [
          INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ,
          INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ,
        ].includes(key)
        || key.includes(INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS)
        || key.includes(CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS)
      ) {
        return {
          ...acc,
          [key]: {
            ...invRepObjOrArrayOfInvRepObjs,
            // Both Brewers and Distributors should set the
            // contractMetadataObject to this
            _contractMetadataObject: createContractMetadataObject(
              'BRW',
              null,
            ),
          },
        }
      }
      return { ...acc, [key]: invRepObjOrArrayOfInvRepObjs }
    },
    {},
  )
}


function* removeInfoJustForWebAppPropFromInventoryReportObjs(props) {
  const {
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  } = props

  return reduce_(
    inventoryReportObjs,
    (acc, invRepObjOrArrayOfInvRepObjs, key) => {
      if (isArray_(invRepObjOrArrayOfInvRepObjs)) {
        return {
          ...acc,
          [key]: invRepObjOrArrayOfInvRepObjs.map(o => omit_(o, [INFO_JUST_FOR_THE_WEB_APP])),
        }
      }
      return {
        ...acc,
        [key]: omit_(invRepObjOrArrayOfInvRepObjs, [INFO_JUST_FOR_THE_WEB_APP]),
      }
    },
    {},
  )
}


/*
 * *****************************************************************************
 * Adding newly-created and edited inventory reports to history form
 * *****************************************************************************
*/

export function* saveInventoryReportObjectsToReduxStoreIfTheyMeetCriteriaOfHistoryForm(
  customerId,
  // an array of inventory report objects, just as they arrive from a POST/PUT /inventoryreports
  inventoryReportObjects,
) {
  if (yield call(getDoInventoryReportObjectsMeetCriteriaOfHistoryForm, customerId, inventoryReportObjects)) {
    const state = yield select()
    const toSave = formatArrayOfInventoryReportsToReduxStoreStructure({
      state,
      customerIdOfCustomerCurrentlyBeingOperatedFor: customerId,
      inventoryReportObjects,
    })

    yield put(createAction(
      SAVE_INVENTORY_HISTORY_ITEMS_OF_MULTIPLE_CUSTOMERS_WITHOUT_DELETING_ANY_EXISTING_HISTORY_ITEMS,
      { info: toSave },
    ))
  }
}

// inventoryReportObjs should be an array of inventoryReportObjects
function* getDoInventoryReportObjectsMeetCriteriaOfHistoryForm(customerId, inventoryReportObjs) {
  const historyFormName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_INVENTORY,
    customerId,
  })
  const mostRecentlySubmittedFormValues = yield select(getMostRecentlySubmittedFormValues, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return false }


  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]

  return doesHistoryItemDateFallWithinHistoryFormDateRange(
    // Choose the first inventory report object in the array passed in because
    // it doesn't matter, they'll all have the same countDate.
    inventoryReportObjs[0].countDate,
    historyFormStartDate,
    historyFormEndDate,
  )
}
