/**
 * CODE_COMMENTS_62
 */
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../../redux/selectors/rewrite/customers'
import {
  getCustomerIdsOfAllRelatedTo,
} from '../../../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireContractsSlice,
  getConbrwCustomerIdOfCONBRWContract,
} from '../../../../../../redux/selectors/rewrite/contracts'

import {
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
} from '../../../../../../constants/formAndApiUrlConfig/histories/OutboundFullKegShipmentHistory'

import {
  SHIPMENT_STATUS_PENDING,
  SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_CANCELED,
  SHIPMENT_STATUS_ON_HOLD,
  SHIPMENT_STATUS_UNDER_REVIEW,
  SHIPMENT_STATUS_PRE_POST,
  SHIPMENT_STATUS_READY_TO_POST,
  SHIPMENT_STATUS_POSTED,
  SHIPMENT_STATUS_SENT_TO_STAGING,
  SHIPMENT_STATUS_SENT_TO_GP,
  SHIPMENT_STATUS_INVOICED,

  COMMON_STATUS_ALIAS_BILLED,
  SHIPMENT_STATUS_ACKNOWLEDGED,
  SHIPMENT_STATUS_PARTIAL_POST, SHIPMENT_STATUS_BOOKED, SHIPMENT_STATUS_IN_TRANSIT,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER, CUSTOMER_TYPES_WAREHOUSE,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CONBRW,
} from '../../../../../../constants'

import {
  createDisplayedShipmentNumber,
  createDisplayedProNumber,
  createDisplayedShipmentType,
  createDisplayedDestinationName,
  createDisplayedDestinationState,
  createDisplayedShipmentDate,
  createItemSkuIdFieldsDefinitions,
  createContainerTypesFieldsDefinitions, createDisplayedMovementType,
} from '../common/displayedShipmentDataEtc'

import {
  createDisplayedOriginNameForOutboundShipmentsHistoryTable,
} from './displayedDataEtc/displayedOutboundFullKegShipmentDataEtc'

import {
  getDisplayedHumanReadableStatusOfHistoryItem,
  getShouldHistoryItemObjBeDisplayedInTable,
  getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm,
  filterHistoryObjectsWithUnrecognizedStatus,
} from '../../util'

import {
  isTruthyAndNonEmpty,
} from '../../../../../../utils'


/*
 * *****************************************************************************
 * statuses config
 * *****************************************************************************
*/

// CODE_COMMENTS_60
export const outboundFullKegShipmentHistoryStatusesConfig = {
  [SHIPMENT_STATUS_PENDING]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_COMPLETED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
    statusShouldBeDisplayedAsThisHumanreadableAlias: 'Finalized',
  },
  [SHIPMENT_STATUS_BOOKED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
    displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder: true,
  },
  [SHIPMENT_STATUS_IN_TRANSIT]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_CANCELED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_ON_HOLD]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_UNDER_REVIEW]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_ON_HOLD,
    includeStatusAsDropdownOptionInHistoryForm: false,
  },
  [SHIPMENT_STATUS_PRE_POST]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
    statusShouldBeDisplayedAsThisHumanreadableAlias: COMMON_STATUS_ALIAS_BILLED,
  },
  [SHIPMENT_STATUS_READY_TO_POST]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_PRE_POST,
  },
  [SHIPMENT_STATUS_POSTED]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_PRE_POST,
  },
  [SHIPMENT_STATUS_PARTIAL_POST]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_PRE_POST,
  },
  [SHIPMENT_STATUS_SENT_TO_STAGING]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_PRE_POST,
  },
  [SHIPMENT_STATUS_SENT_TO_GP]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_PRE_POST,
  },
  [SHIPMENT_STATUS_INVOICED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_ACKNOWLEDGED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
}


export const getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable = shipmentObj => (
  getShouldHistoryItemObjBeDisplayedInTable(
    shipmentObj,
    outboundFullKegShipmentHistoryStatusesConfig,
    'status',
  )
)

export const getDisplayedHumanReadableStatusOfOutboundFullkegShipment = shipmentObj => (
  getDisplayedHumanReadableStatusOfHistoryItem(
    shipmentObj,
    outboundFullKegShipmentHistoryStatusesConfig,
    'status',
  )
)


// CODE_COMMENTS_145
export const getShouldOutboundShipmentHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm = (
  currentlySelectedStatusInHistoryForm,
  shipmentObj,
) => (
  getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm(
    currentlySelectedStatusInHistoryForm,
    shipmentObj,
    outboundFullKegShipmentHistoryStatusesConfig,
    'status',
  )
)

/*
 * *****************************************************************************
 * History table
 * *****************************************************************************
*/

// CODE_COMMENTS_130
export function getShouldOriginColumnBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  customerType,
  kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm, // CODE_COMMENTS_232
}) {
  if (kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm) {
    return true
  }

  // If this is a contractee brewer being operated on by a Contract Brewer, do
  // not show the 'Origin' column
  if (operatingContractBrewerCustomerId) { return false }

  if (customerType !== CUSTOMER_TYPES_BREWER) { return false }

  const contractBrewerIdsRelatedTo = getCustomerIdsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    // The origin column should be shown if the brewer _has ever been_ related
    // to Contract Brewers, even if those relationships can't have keg fills
    // reported on them anymore.
    onlyRelationshipsThatShipmentsCanBeReportedOn: false, // CODE_COMMENTS_263
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
  })

  if (!isTruthyAndNonEmpty(contractBrewerIdsRelatedTo)) { return false }

  // TODO permissions: check that one or more of the
  // currentUser.perCustomerPermissions[contractBrewerCustomerId] objects for
  // each related-to contract brewer customer ID contains the following object
  // {permission: "SHIPMENTS", access: "READ"}

  return true
}


// CODE_COMMENTS_134. Returns either the brewer's customerId, the contract
// Brewer's customerId, or null if this is a backend bug in which the origin of
// an outbound shipment comes not from either the Brewer's own location nor the
// Brewer's contract brewing contract location but from a customer the Brewer is
// not related to at all (such shipments should not have been included in the
// outbound full keg shipments fetch made by the web app).
export function getOriginCustomerIdForOutboundShipmentsHistoryTableAssumingContracteeFillsMeanConbrwOrigin(
  state,
  brewerCustomerId,
  row,
) {
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const contract = entireContractsSlice[row.originContractId]
  if (!contract) {
    // If the shipment didn't originate at the Brewer's default location nor any
    // of its Contract Brewer's locations, it shouldn't have been included in the
    // response from the fetching of this customer's outbound shipment history,
    // therefore this is a bug. Return null, but don't log an error to our
    // 3rd-party service here, because a different part of the code which calls
    // this function will log that error.
    return null
  } else if (contract.ppfContractType === PPF_CONTRACT_TYPES_BRW) {
    if (contract.customerId === brewerCustomerId) {
      return brewerCustomerId
    }
    // This is an error for the same reason as ablve, so return null
    return null
  } else if (contract.ppfContractType === PPF_CONTRACT_TYPES_CONBRW) {
    // This must be a shipment from a Contract Brewer's location
    return getConbrwCustomerIdOfCONBRWContract({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      contract,
    })
  }
  // Again, an error
  return null
}


/*
 * *****************************************************************************
 * Full Details Modal
 * *****************************************************************************
*/

// Used by both the Full Details modal and the "Download File As CSV" feature
export const getInfoDefinitionsOutboundFullKegShipmentFullDetails = ({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  shouldOriginColumnBeRendered,
  downloadAttachment,
  entireCustomersSlice,
  customerId,
}) => ([
  { heading: 'Shipment #', cellContent: createDisplayedShipmentNumber },
  { heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM, cellContent: createDisplayedProNumber('none') },
  ...(entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ?
    [{ heading: 'Shipment Type', cellContent: createDisplayedShipmentType }]
    : [{ heading: 'Movement Type', cellContent: createDisplayedMovementType }]
  ),
  ...(shouldOriginColumnBeRendered
    ? [{
      heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
      cellContent: createDisplayedOriginNameForOutboundShipmentsHistoryTable,
    }]
    : []
  ),
  { heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION, cellContent: createDisplayedDestinationName },
  { heading: 'Destination State', cellContent: createDisplayedDestinationState },
  { heading: 'Ship Date', cellContent: createDisplayedShipmentDate },
  { heading: 'Status', cellContent: getDisplayedHumanReadableStatusOfOutboundFullkegShipment },
  ...(downloadAttachment ?
    createContainerTypesFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
    })
    :
    createItemSkuIdFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
    })),
])


export function getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
  allOutboundFullKegShipmentObjects,
) {
  return filterHistoryObjectsWithUnrecognizedStatus( // CODE_COMMENTS_156
    allOutboundFullKegShipmentObjects,
    outboundFullKegShipmentHistoryStatusesConfig,
    'status',
  )
}
