import React, { Fragment, useState, useEffect } from 'react'
import { Table } from 'semantic-ui-react'

import get_ from 'lodash/get'


import { useTranslation } from 'react-i18next'
import {
  createTableContent,
} from './util'


export default ({
  tableInfoObj,
  definitions,
  additionalPropsToPassToTableCells,
  itemType,
  customerId,
  operatingContractBrewerCustomerId,
  areAnyItemsEditable,
  getIsindividualItemEditable,
  dontIncludeDeleteButton,
  noResultsMessage,
  // For things like the NoMovements objects within the Shipments history tables
  specialInfoObj,
  specialTableContentDefinitions,
}) => {
  const { t: translate } = useTranslation('common')
  const [{ sortColumn, sortDirection }, setSort] = useState({
    sortColumn: definitions.defaultSortColumn,
    sortDirection: 'desc',
  })
  const handleSort = clickedColumn => () => {
    const columnDef = definitions.content.find(o => o.heading === clickedColumn)
    if (
      // When would we not find the columnDef? On the Edit/Delete column, which
      // is only added to definitions AFTER definitions gets passed in to this
      // component.
      !columnDef
      || get_(columnDef, ['customSortInfo', 'columnNotSortable'])
    ) {
      return
    }
    if (clickedColumn !== sortColumn) {
      setSort({
        sortColumn: clickedColumn,
        sortDirection: 'desc',
      })
      return
    }
    setSort({
      sortColumn,
      sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
    })
  }

  const [{ tableHeadings, mainTableRows, totalsRow }, setTableContent] = useState(createTableContent({
    tableInfoObj,
    definitions,
    additionalPropsToPassToTableCells,
    itemType,
    customerId,
    operatingContractBrewerCustomerId,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    dontIncludeDeleteButton,
    // For things like the NoMovements objects within the Shipments history tables
    specialInfoObj,
    specialTableContentDefinitions,
    sortColumn,
    sortDirection,
  }))
  useEffect(
    () => {
      setTableContent(createTableContent({
        tableInfoObj,
        definitions,
        additionalPropsToPassToTableCells,
        itemType,
        customerId,
        operatingContractBrewerCustomerId,
        areAnyItemsEditable,
        getIsindividualItemEditable,
        dontIncludeDeleteButton,
        // For things like the NoMovements objects within the Shipments history tables
        specialInfoObj,
        specialTableContentDefinitions,
        sortColumn,
        sortDirection,
      }))
    },
    [
      tableInfoObj,
      definitions,
      additionalPropsToPassToTableCells,
      itemType,
      customerId,
      operatingContractBrewerCustomerId,
      areAnyItemsEditable,
      getIsindividualItemEditable,
      dontIncludeDeleteButton,
      // For things like the NoMovements objects within the Shipments history tables
      specialInfoObj,
      specialTableContentDefinitions,
      sortColumn,
      sortDirection,
    ],
  )


  return (
    <Fragment>
      <Table sortable compact textAlign="center">
        <Table.Header>
          <Table.Row>
            {tableHeadings.map(({ heading, className }) => (
              <Table.HeaderCell
                key={heading}
                className={className}
                // For sorting
                sorted={
                  sortColumn === heading
                    ? (sortDirection === 'asc' ? 'ascending' : 'descending')
                    : null
                }
                onClick={handleSort(heading)}
              >
                {translate(heading)}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {mainTableRows.map(({ rowKey, rowContent }) => (
            <Table.Row key={rowKey}>
              {rowContent.map((cellContent, cellIndex) => (
                <Table.Cell
                  key={`${rowKey}${tableHeadings[cellIndex].heading}`}
                  className={tableHeadings[cellIndex].className}
                  {...tableHeadings[cellIndex].additionalSuiTableCellProps}
                >
                  {cellContent}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>

        {
          totalsRow && // If totalsRow is undefined, don't include a totals row at all
          <Table.Footer>
            <Table.Row className="history-table-totals-row">
              <Table.HeaderCell>{translate('Totals')}</Table.HeaderCell>
              {
                // slice(1) chops off the first item in the array: the first
                // column is not allowed to be part of the totals row because
                // that's where the "Totals:" label goes. If the history table
                // definition specifies that the first column should be part of
                // the Totals row, it won't cause any error, this component will
                // simply ignore it.
                totalsRow.slice(1).map((totalCellContent, index) => (
                  <Table.HeaderCell
                    key={tableHeadings?.[index+1]?.heading}
                    className={tableHeadings?.[index+1]?.className}
                  >
                    {
                      totalCellContent || totalCellContent === 0
                        ? totalCellContent
                        // if totalCellContent is undefined, leave this cell blank
                        : null
                    }
                  </Table.HeaderCell>
                ))
              }
            </Table.Row>
          </Table.Footer>
        }

      </Table>
      {
        mainTableRows.length === 0
          ? <p>{translate(noResultsMessage)}</p>
          : null
      }
    </Fragment>
  )
}
