import React from 'react'
import { Form, Button, Icon } from 'semantic-ui-react'
import InputNoAutoComplete from '../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'


import DismissableMessage from '../../../../common-components/DismissableMessage'
import RevealFetchErrorDetails from '../../../../common-components/fetchErrorDetails/RevealFetchErrorDetails'

import {
  DISPLAYED_ERROR_MESSAGES_FOR_EMPLOYEES_UNKNOWN_SERVER_ERROR,
} from '../../../../constants'

import {
  getIsStringAValidEmailAddress,
} from '../../../../utils'

import {
  LOGIN_EMAIL_MAX_LENGTH,
} from '../../../../config'


const emailAddressRequiredErrorText = 'Email address required'


export default class OperateAsCustomerUser extends React.Component {
  state = {
    emailInput: '',
    emailInputTouched: false, // True when the user first enters a value
    emailErrorText: null, // only show error message after value has been touched
    mostRecentlySubmittedEmailInput: '',
  }

  onEmailInputChange = e => {
    let emailInputToSave = e.target.value
    // strip whitespace from string
    emailInputToSave = emailInputToSave.replace(/ /g, '')

    let emailErrorText

    if (!getIsStringAValidEmailAddress(emailInputToSave)) {
      emailErrorText = 'Invalid email address'
    } else {
      emailErrorText = null
    }

    if (['', undefined].includes(emailInputToSave)) {
      emailErrorText = emailAddressRequiredErrorText
    }

    // max length rule
    if (emailInputToSave.length > LOGIN_EMAIL_MAX_LENGTH) {
      emailInputToSave = this.state.emailInput
    }

    this.setState({
      emailInput: emailInputToSave,
      emailInputTouched: true,
      emailErrorText,
    })
  }

  onSubmit = e => {
    e.preventDefault()
    const { emailInput } = this.state
    this.props.onSubmit(emailInput)
    this.setState({
      mostRecentlySubmittedEmailInput: emailInput,
    })
  }

  render() {
    const {
      emailInput,
      emailErrorText,
      emailInputTouched,
    } = this.state
    const {
      isFetching,
      didFetchFail,
      errorDetails,
      onClearFetchStatuses,
      t: translate = a => a,
    } = this.props

    let errorMessageToDisplay
    let shouldErrorDetailsBeDisplayed = false
    if (didFetchFail) {
      const { errorMessage, url, errorCode } = errorDetails
      // If the email searched for is an employee user rather than a customer user
      if (errorMessage.includes('is an employee user')) {
        errorMessageToDisplay = errorMessage
      // If the email searched for doesn't exist in the database
      } else if (errorCode === 404 && !url.includes('/permissions')) {
        errorMessageToDisplay = `${this.state.mostRecentlySubmittedEmailInput} not found`
      } else {
        errorMessageToDisplay = DISPLAYED_ERROR_MESSAGES_FOR_EMPLOYEES_UNKNOWN_SERVER_ERROR
        shouldErrorDetailsBeDisplayed = true
      }
    }

    return (
      <div>
        <Form
          onSubmit={this.onSubmit}
          // This class name helps with formatting. It's a badly-named class
          // because, obviously, this isn't a public page.
          className="public-page-container"
        >
          <div className="field-with-error-message">
            <InputNoAutoComplete
              fluid
              placeholder={translate('Customer email address')}
              type="text"
              name="email"
              value={emailInput}
              onChange={this.onEmailInputChange}
              disabled={isFetching}
            />
            {
              emailErrorText
              // For public forms, I like to show an 'Email address required'
              // error text when either 1) the user clicks into the input but
              // then clicks out of it withour typing anything; 2) the user
              // types characters into the field but then deletes them all,
              // leaving a blank input. Such hand-holding isn't necessary for
              // employee users, and this kind of error message can be annoying,
              // so I don't show it in employee forms.
              && emailErrorText !== emailAddressRequiredErrorText
                ? <small>{translate(emailErrorText)}</small>
                : null}
          </div>
          {isFetching
            ? <Button type="submit" fluid animated loading disabled>{translate('Loading')}</Button>
            : (
              <Button
                type="submit"
                fluid
                animated
                disabled={Boolean(emailErrorText) || !emailInputTouched}
                color={Boolean(emailErrorText) || !emailInputTouched || isFetching ? null : 'green'}
              >
                <Button.Content visible>{translate('Search')}</Button.Content>
                <Button.Content hidden>
                  <Icon name="right arrow" />
                </Button.Content>
              </Button>
            )
          }
        </Form>
        {didFetchFail &&
          <ErrorMessage
            errorMessage={errorMessageToDisplay}
            onClearFetchStatuses={onClearFetchStatuses}
            errorDetails={errorDetails}
            shouldErrorDetailsBeDisplayed={shouldErrorDetailsBeDisplayed}
          />
        }
      </div>
    )
  }
}


const ErrorMessage = ({
  errorMessage,
  onClearFetchStatuses,
  errorDetails,
  shouldErrorDetailsBeDisplayed,
}) => (
  <DismissableMessage
    style={{ clear: 'both', marginBottom: '15px' }}
    error
    onDismiss={onClearFetchStatuses}
    content={
      <div>
        <p>{errorMessage}</p>
        {errorDetails && shouldErrorDetailsBeDisplayed &&
          <div>
            <RevealFetchErrorDetails
              errorDetails={errorDetails}
              asPopup
            />
          </div>
        }
      </div>
    }
  />
)
